.PersonWin {
  box-sizing: border-box;
  width: 100%;
  border-radius: 16px;
  text-align: center;
  padding-bottom: 20px;
  background: #fff;
  overflow-y: auto;
  height: calc(100vh - 70px);

  .listImg {
    display: inline-block;
    width: 100px;
    border-radius: 100px;
    height: 100px;
    margin-top: 32px;
    cursor: pointer;
    object-fit: cover;
  }

  .listName {
    margin-top: 10px;
    text-align: center;
    cursor: pointer;

    font-family: PingFang SC;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #394a70;
    &:hover {
      color: #326ffd;
    }
  }

  .subName {
    margin-top: 6px;
    padding: 0 20px;
    text-align: center;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* identical to box height */

    color: #767582;
  }

  .countPart {
    width: 336px;
    height: 70px;
    margin-top: 12px;
    display: inline-block;
    border-top: 1px solid #dcdff3;
    border-bottom: 1px solid #dcdff3;

    .countItem {
      display: inline-block;
      width: 100px;
      height: 70px;
      position: relative;
      .count {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        margin-top: 12px;
        line-height: 20px;
        text-align: center;
        /* identical to box height */
        margin-bottom: 8px;
        color: #394a70;
      }
      .countType {
        font-family: PingFang SC;
        font-style: normal;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        /* identical to box height */

        color: #767582;
      }

      &:nth-child(2) {
        &::before {
          content: "";
          position: absolute;
          height: 22px;
          width: 1px;
          left: 0px;
          top: 24px;

          background: #dcdff3;
        }

        &::after {
          content: "";
          position: absolute;
          height: 22px;
          width: 1px;
          right: 0px;
          top: 24px;

          background: #dcdff3;
        }
      }
    }
  }

  .chartpart {
    position: relative;
    margin-top: 12px;
    .title {
      height: 17px;
      margin-left: 16px;
      text-align: left;
      padding-left: 8px;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 100;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height */

      color: #394a70;

      // &::after {
      //   content: "";

      //   position: absolute;
      //   width: 2px;
      //   height: 14px;
      //   left: -2px;
      //   top: 1px;

      //   background: #3284ff;
      // }
    }
    .chart {
      width: 336px;
      height: 120px;
      display: inline-block;
    }
  }

  .introduce {
    color: #263b5f;
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    display: inline-block;
    // height: calc(100vh - 494px);
    font-weight: 400;
    line-height: 150%; /* 24px */
    text-align: left;
    margin: 0 32px;
    margin-top: 18px;
    border-top: 1px solid #e0e2e5;
    padding-top: 18px;

    .text {
      color: #263b5f;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
  }
}

.emptyTip {
  color: #b5b5b5;
  padding: 40px;
  text-align: center;
}
.emptyPart {
  display: inline-block;
  width: 125px;
  margin-top: 20px;

  img {
    width: 105px;
    height: 84px;
  }
  p {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #90959d;
  }
}
