.searchTabs {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  top: 0px;

  .searchBox {
    height: 48px;
    border-radius: 36px;
    border: 1px solid rgba(38, 59, 95, 0.2);
    background: #fff;
    width: 768px;
    margin-left: 400px;
    padding: 4px 16px;
    vertical-align: middle;

    .splitLine {
      width: 1px;
      height: 18px;
      display: inline-block;
      vertical-align: middle;
      background: #d5d8de;
    }
    .closeImg {
      width: 20px;
      vertical-align: middle;
      height: 20px;
      display: inline-block;
      margin-top: 2px;
      margin-right: 10px;
    }
    &:hover {
      border-radius: 36px;
      border: 1px solid rgba(38, 59, 95, 0.5);
      background: #fff;
    }
    .iconfont {
      color: #949daf !important;
      vertical-align: middle;
      font-size: 20px;
      cursor: pointer;
      &:hover {
        color: #2b3a5f !important;
      }
    }

    :global {
      .ant-input {
        background-color: #fff !important;
        color: #263b5f;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &::placeholder {
          color: rgba(38, 59, 95, 0.7);
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  .tab {
    margin-left: 60px;
    display: flex;
    vertical-align: middle;
    justify-items: center;
    align-items: center;
    gap: 40px;
    width: 280px;
    .tabItem {
      display: inline-block;
      cursor: pointer;
      .tabItemName {
        color: #263b5f;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .tabItemIcon {
        color: #263b5f;
        margin-right: 4px;
      }
    }
    .tabItemActive {
      .tabItemName {
        color: #326ffd;
      }
      .tabItemIcon {
        color: #326ffd;
      }
    }
  }
  .tabCt {
    max-width: 1000px;
    margin-left: 416px;
  }

  @media screen and (max-width: 1620px) {
    .searchBox {
      margin-left: 140px;
    }
    .tabCt {
      margin-left: 156px;
    }
    .searchBox {
      width: 640px;
    }
  }

  :global {
    .ant-tabs-nav-list {
      margin-left: 414px;
    }
    @media screen and (max-width: 1620px) {
      .ant-tabs-nav-list {
        margin-left: 154px;
      }
    }
    .ant-tabs-ink-bar {
      border-radius: 11px;
      background: #326ffd;
      width: 50px;
      height: 3px;
      flex-shrink: 0;
    }
    .ant-tabs .ant-tabs-tab-btn {
      color: rgba(38, 59, 95, 0.7);
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      padding: 0 8px;
      margin: 0 8px;
      line-height: normal;

      &:hover {
        color: #326ffd !important;
      }
    }
    .ant-tabs-nav .ant-tabs-ink-bar-animated {
      transition: none;
    }
    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #263b5f;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .ant-tabs-top > .ant-tabs-nav::before {
      border-top: 1px solid #e0e2e5;
      bottom: -1px !important;
    }
  }

  .loginBox {
    position: absolute;
    right: 80px;
    top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .avatar {
      width: 28px;
      height: 28px;
      display: inline-block;
      border-radius: 28px;
      vertical-align: middle;
      margin-right: 8px;
    }
    .loginIcon {
      color: #263b5f;
      font-size: 28px;
      margin-right: 12px;
    }
    .statusText {
      color: #263b5f;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      cursor: pointer;
      line-height: normal;
    }
  }
}
.hide {
  display: none;
}
.headerLogin {
  .dropdown {
    min-width: 106px;
  }
  .accountInfo {
    display: flex;
    width: 288px;
    align-items: center;
    padding: 16px;
    color: rgba(46, 46, 46, 0.88);
    background-color: #f4f5f7;
    vertical-align: middle;
    cursor: pointer;
    box-sizing: border-box;
  }

  .avatar {
    width: 64px;
    vertical-align: middle;
    height: 64px;
    margin-right: 12px;
    border-radius: 50%;
    object-fit: cover;
  }
  .item {
    height: 40px;
    padding: 12px 16px;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    &:hover {
      background: rgba(27, 96, 233, 0.1);
    }

    .icon {
      margin-right: 6px;
      vertical-align: middle;
      font-size: 16px;
    }
  }

  :global {
    .ant-popover-inner {
      min-width: 106px;
      border-radius: 8px;
      box-shadow: 0 2px 13px #378dd55e;
      padding: 10px 0;
    }
  }
}
.show {
  display: block !important;
}
.unLoginBox {
  width: 95px;
  height: 36px;
  position: absolute;
  right: 20px;
  cursor: pointer;
  background: rgba(27, 96, 233, 1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #5583ed;
  }
  .statusText {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
  }
}
