.expandable-text {
  position: relative;
  max-height: 3.6em; // 假设每行高度为1.8em，两行则是3.6em
  overflow: hidden;
  line-height: 1.8em;
  overflow: hidden;
  font-size: 16rem;
  transition: max-height 0.3s ease;
  color: #ebeef7;
  &.expanded {
    max-height: 100%; // 展开时移除max-height限制
  }
}

.expandable-btn {
  font-family: PingFang SC;
  font-size: 14rem;
  font-weight: 400;
  line-height: 20rem;
  letter-spacing: 0em;
  text-align: left;
  color: #2452bf;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  padding: 2rem 10rem;
  padding-left: 0rem;
  cursor: pointer;
  &:hover {
    color: #326ffd;
    .expandable-icon {
      color: #326ffd;
    }
  }
}
.expandable-even {
  background: #15204b;
  &::before {
    content: "";
    position: absolute;
    width: 20rem;
    background: linear-gradient(
      90deg,
      rgba(24, 31, 75, 0.2),
      rgba(24, 31, 75, 1)
    );
    height: 20rem;
    left: -20rem;
    display: inline-block;
  }
}
.expandable-odd {
  background: #101d41;
  &::before {
    content: "";
    position: absolute;
    width: 20rem;
    background: linear-gradient(
      90deg,
      rgba(24, 31, 75, 0.2),
      rgba(24, 31, 75, 1)
    );
    height: 20rem;
    left: -20rem;
    display: inline-block;
  }
}
.expandable-icon {
  color: #2452bf;
  font-size: 12rem;
}
