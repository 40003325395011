.FeedBackModal {
  display: inline-block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);

  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 8px;
    background: #fff;
    padding: 24px 32px;
    width: 564px;
    height: 334px;
    flex-shrink: 0;
    .closeIcon {
      position: absolute;
      top: 24px;
      cursor: pointer;
      font-size: 20px;
      right: 32px;
      color: rgba(0, 0, 0, 0.5);
      &:hover {
        opacity: 0.7;
      }
    }
    .title {
      color: #263b5f;
      font-family: PingFang SC;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .tag {
      margin-top: 12px;
      .text {
        display: inline-flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        color: #263b5f;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        border-radius: 4px;
        border: 1px solid #e0e2e5;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
        &:hover {
          border: 1px solid #326ffd;
          background: rgba(50, 111, 253, 0.1);
        }
      }
      .textActive {
        color: #326ffd;
        background: rgba(50, 111, 253, 0.1);
      }
    }
    .textArea {
      margin-top: 16px;
      padding-bottom: 20px;
      :global {
        .ant-input-data-count {
          bottom: 4px;
          right: 8px;
          color: #90959d;
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .btn {
      float: right;
      margin-top: 16px;
      height: 38px;
      width: 72px;
      display: inline-flex;
      padding: 8px 20px;
      justify-content: center;
      align-items: center;
      display: inline-flex;
      padding: 8px 20px;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      box-shadow: none !important;
    }
    .btnDisable {
      background-color: rgba(50, 111, 253, 0.4) !important;
      cursor: not-allowed;
      box-shadow: none !important;
    }
  }
}
