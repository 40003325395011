.logo {
  position: absolute;
  width: 260px;
  left: 70px;
  top: 20px;
  height: 40px;
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: center;

  .darkLogo {
    .fullLogo {
      width: 260px;
      height: 40px;
      top: 0px;
      position: absolute;
      left: 0px;
      display: inline-block;
      background: url(../../image/logo/darkLogo.svg) no-repeat;
      background-size: cover;
      z-index: 2;
    }
    .logoCircle {
      z-index: 3;
      top: 0px;
      height: 40px;
      width: 40px;
      cursor: pointer;
      position: absolute;
      left: 0px;
      display: inline-block;
      background: url(../../image/gkx/gkxLogoCircle.svg) no-repeat;
      background-size: cover;
    }
  }
  .lightLogo {
    .fullLogo {
      width: 260px;
      height: 40px;
      position: absolute;
      left: 0px;
      top: 0px;
      display: inline-block;
      background: url(../../image/logo/lightLogo.svg) no-repeat;
      background-size: cover;
      z-index: 3;
    }
    .logoCircle {
      z-index: 3;
      height: 40px;
      width: 40px;
      cursor: pointer;
      position: absolute;
      left: 0px;
      top: 0px;
      display: inline-block;
      background: url(../../image/gkx/gkxLogoCircle.svg) no-repeat;
      background-size: cover;
    }
  }
  // .darkTitle {
  //   font-family: PingFang TC;
  //   font-size: 20px;
  //   font-weight: 500;
  //   line-height: 28px;
  //   color: #fff;
  // }
  // .lightTitle {
  //   font-family: PingFang TC;
  //   font-size: 20px;
  //   font-weight: 500;
  //   line-height: 28px;
  //   color: #263b5f;
  // }
}
.light {
  width: 100vw;
  height: 54px;
  z-index: 999;
  display: flex;
  padding-right: 80px;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  .pageIcon {
    position: absolute;
    right: 190px;
    cursor: pointer;
    top: 26px;
    &:hover {
      span {
        color: #326ffd;
      }
    }
    span {
      margin-left: 4px;
      color: #263b5f;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .tab {
    margin-left: 376px;

    margin-top: 32px;
    .tabItem {
      display: inline-block;
      margin-right: 60px;
      color: #a3a7b9;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      padding-bottom: 6px;

      transition: all 0.3s linear;
      color: rgba(38, 59, 95, 0.7);
      &:hover {
        color: #263b5f;
      }
      .tabItemName {
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .tabItemIcon {
        margin-right: 4px;
      }
    }

    .tabItemActive {
      border-bottom: 2px solid #326ffd;
      .tabItemName {
        color: #326ffd;
      }
      .tabItemIcon {
        color: #326ffd;
      }
    }
  }
  .loginBox {
    display: flex;
    position: absolute;
    right: 60px;
    top: 25px;
    align-items: center;
    justify-content: center;
    .avatar {
      width: 28px;
      height: 28px;
      display: inline-block;
      border-radius: 28px;
      vertical-align: middle;
      margin-right: 8px;
    }
    .loginIcon {
      color: #263b5f;
      font-size: 28px;
      margin-right: 12px;
    }
    .statusText {
      color: #263b5f;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      cursor: pointer;
      line-height: normal;
    }
  }
}

.unLoginBox {
  width: 95px;
  height: 36px;
  position: absolute;
  right: 60px;
  top: 20px;
  cursor: pointer;
  background: rgba(27, 96, 233, 1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #5583ed;
  }
  .statusText {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
  }
}

.dark {
  width: 100vw;
  padding-right: 80px;
  height: 54px;
  z-index: 999;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: space-between;
  .pageIcon {
    position: absolute;
    right: 190px;
    cursor: pointer;
    top: 26px;
    &:hover {
      span {
        color: #fff;
      }
    }
    span {
      margin-left: 4px;
      color: rgb(163, 167, 185);
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .tab {
    margin-left: 376px;
    margin-top: 32px;
    .tabItem {
      display: inline-block;
      margin-right: 60px;
      transition: all 0.3s linear;
      color: #a3a7b9;
      border-bottom: 2px solid transparent;
      padding-bottom: 6px;

      cursor: pointer;
      &:hover {
        color: #fff;
      }
      .tabItemName {
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .tabItemIcon {
        margin-right: 4px;
      }
    }

    .tabItemActive {
      border-bottom: 2px solid #fff;
      .tabItemName {
        color: #fff;
      }
      .tabItemIcon {
        color: #fff;
      }
    }
  }
  .loginBox {
    margin-top: 5px;
    display: flex;
    align-items: center;
    position: absolute;
    right: 60px;
    top: 20px;
    justify-content: center;
    .avatar {
      width: 28px;
      height: 28px;
      display: inline-block;
      border-radius: 28px;
      vertical-align: middle;
      margin-right: 8px;
    }
    .loginIcon {
      color: #fff;
      font-size: 28px;
      margin-right: 12px;
    }
    .statusText {
      color: #fff;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      cursor: pointer;
      line-height: normal;
    }
  }
}
.screen {
  background-color: #041b47 !important;
  position: relative !important;
}

.headerLogin {
  .dropdown {
    min-width: 106px;
  }

  .accountInfo {
    display: flex;
    width: 288px;
    align-items: center;
    padding: 16px;
    color: rgba(46, 46, 46, 0.88);
    background-color: #f4f5f7;
    vertical-align: middle;
    cursor: pointer;
    box-sizing: border-box;
  }

  .avatar {
    width: 64px;
    vertical-align: middle;
    height: 64px;
    margin-right: 12px;
    border-radius: 50%;
    object-fit: cover;
  }
  .item {
    height: 40px;
    padding: 12px 16px;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    &:hover {
      background: rgba(27, 96, 233, 0.1);
    }

    .icon {
      margin-right: 6px;
      vertical-align: middle;
      font-size: 16px;
    }
  }

  :global {
    .ant-popover-inner {
      min-width: 106px;
      border-radius: 8px;
      box-shadow: 0 2px 13px #378dd55e;
      padding: 10px 0;
    }
  }
}

.loginBtn {
  transition: none;
  color: #263b5f;

  width: 100%;
  padding: 0 20px 0 10px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 0;
  cursor: pointer;
  &:hover {
    background: #1b60e9;
    color: #fff;
  }
}
