@mainColor: #90959dff;
@secondaryColor: #263b5fff;
@bgColor: #ffffffff;

.rootFrame2863 {
  min-height: 128px;
  width: 997px;
  position: relative;
  color: @mainColor;
  padding: 16px 20px;
  font-family: "PingFang SC";
  font-size: 14px;
  background: rgba(38, 59, 95, 0.03);
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  .flex {
    display: flex;
    margin-top: 14px;
    gap: 4px;
    .label {
      display: inline-block;
      color: #90959d;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 70px;
    }
    .text2 {
      color: #263b5f;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 4px 8px;
      border-radius: 4px;
      background: #fff;
      margin-top: -4px;
    }
    .emptyString {
      color: #263b5f;
      vertical-align: middle;
      padding: 3px 0px;
    }
    .content {
      flex-wrap: wrap;
      gap: 8px;
      display: flex;
      position: relative;
      flex: 1;
      .item {
        padding: 4px 8px;
        border-radius: 4px;
        background: #fff;
        .text {
          color: #90959d;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .text1 {
          color: #263b5f;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
