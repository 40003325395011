.chat {
  width: 100%;
  background: linear-gradient(180deg, #fbfbfb 0%, #e7f0ff 100%);
  padding-top: 80px;
  height: 100vh;

  .chatContent {
    width: 100%;
    height: calc(100vh - 120px);
  }
}
