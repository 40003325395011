.DetailModal {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999999999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    width: 1252rem;
    height: 720rem;
    display: inline-block;
    border-radius: 2rem;
    border: 1rem solid rgba(88, 142, 248, 0.62);
    background: #001c46;

    .topBar {
      background: #012158;
      height: 56rem;
      position: relative;
      .title {
        font-family: PingFang SC;
        font-size: 20rem;
        font-style: normal;
        line-height: 56rem;
        font-weight: 600;
        background: linear-gradient(180deg, #bacdff 0%, #82bbff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-left: 46rem;
      }
      .icon {
        font-size: 24rem;
        color: #fff;
        position: absolute;
        right: 17rem;
        top: 15rem;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }

    .mainBody {
      padding: 24rem 47rem;
      .name {
        color: #fff;
        font-family: PingFang SC;
        font-size: 20rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .itemList {
        width: 1160rem;
        margin-top: 16rem;
        .itemFull {
          width: 1160rem;
          box-sizing: border-box;
          border: 1rem solid #2c3e97;

          display: flex;

          .text {
            max-height: 200rem;
            overflow-y: auto;
          }
        }
        .itemFullFixed {
          width: 1160rem;
          box-sizing: border-box;
          border: 1rem solid #2c3e97;
          display: flex;

          .text {
            height: 160rem;
            overflow-y: auto;
          }
        }
        .item {
          width: 580rem;
          display: inline-block;
          box-sizing: border-box;
          border: 1rem solid #2c3e97;
        }
        .label {
          display: inline-block;
          width: 180rem;
          padding: 9rem 20rem;
          color: #95bfff;
          background: #012158;
          color: #95bfff;
          font-family: PingFang SC;
          font-size: 16rem;
          font-style: normal;
          font-weight: 400;
        }
        .text {
          display: inline-block;
          background: #001c46;
          flex: 1;
          padding: 9rem 20rem;
          color: #fff;
          color: #fff;
          font-family: PingFang SC;
          font-size: 16rem;
          font-style: normal;
          font-weight: 400;
        }
        .textLink {
          color: #5487ff;
          cursor: pointer;
        }
      }
    }
  }
}
