.authorList {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  margin: 8px 0;

  .authorName {
    color: #90959d;
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 4px;
    &:not(:last-child):after {
      content: ", ";
    }
  }
  .authorNameActive {
    cursor: pointer;
    color: #5487ff;
    &:hover {
      text-decoration: underline;
    }
  }
  .showMore {
    color: #90959d;
    font-family: PingFang SC;
    font-size: 12px;
    margin-right: 8px;
    margin-left: 8px;
    font-style: normal;
    font-weight: 400;
    display: inline-flex;
    cursor: pointer;
    border: none;
    padding: 4px 8px;
    background: rgba(144, 149, 157, 0.2);
    justify-content: center;
    align-items: center;
    gap: 10px;
    line-height: normal;
    &:hover {
      background: rgba(144, 149, 157, 0.3);
    }
  }
  .showMoreActive {
    color: rgba(50, 111, 253, 0.8);
    font-family: PingFang SC;
    font-size: 12px;
    margin-right: 8px;
    margin-left: 8px;
    font-style: normal;
    font-weight: 400;
    display: inline-flex;
    cursor: pointer;
    border: none;
    padding: 4px 8px;
    justify-content: center;
    background: rgba(50, 111, 253, 0.1);
    align-items: center;
    gap: 10px;
    line-height: normal;
    &:hover {
      color: #5487ff;
      background: rgba(50, 111, 253, 0.15);
    }
  }
}

.authorListShowAll {
  margin-top: 10px !important;
}
.noMargin {
  margin: 0px !important;
}
.authorListBlack {
  .showMore {
    color: #a3a7b9;
    background: #39456c;
    &:hover {
      background: #586182;
      color: #ebeef7;
    }
  }
  .authorName {
    color: #a3a7b9;
  }
  .authorNameActive {
    cursor: pointer;
    color: #5487ff;
  }
}
.listItem {
  display: flex;
  .leftIcon {
    width: 64px;
    height: 64px;
    cursor: pointer;
    margin-right: 12px;
    border-radius: 64px;
    object-fit: cover;
  }
  .rightPart {
    flex: 1;
    max-width: 1410px;
    .topInfo {
      .topTitle {
        color: #263b5f;
        font-family: PingFang TC;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        min-width: 200px;

        cursor: pointer;
        &:hover {
          color: #5487ff;
        }
      }
      .region {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-top: 2px;
        /* identical to box height */
        vertical-align: text-bottom;
        color: #b5b5b5;
      }
    }
    .jumpLink {
      color: #5487ff;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 2;
      cursor: pointer;
    }
    .belongOrg {
      color: #90959d;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      margin-top: 4px;
      height: 16px;
      line-height: 16px;
      font-weight: 400;
      max-width: 320px;
    }

    .content {
      color: #263b5f;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .bottomInfo {
      .bottomCount {
        vertical-align: middle;
        display: flex;
        gap: 10px;
        vertical-align: middle;
        justify-content: left;
        .bottomDetail {
          display: inline-block;
          color: #263b5f;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }
}

.popover {
  :global {
    .ant-popover-inner {
      background-color: #283d6e !important;
    }
  }
}
.listItemBlack {
  .rightPart {
    .topInfo {
      .topTitle {
        color: #fff !important;
      }
    }
    .bottomInfo {
      .bottomDetail {
        color: #fff !important;
      }
      .bottomCount {
        color: #fff !important;
      }
    }
  }
}
