.table {
  :global {
    /* 滚动槽 */
    ::-webkit-scrollbar {
      width: 4px;
      height: 6px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 3px;
      background: transparent;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
    }
    /* 滚动条滑块 */
    ::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: #1d4e95;
      -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }
}
