.info {
  width: 100vw;
  position: fixed;
  bottom: 0;
  height: 220px;
  .infoTop {
    width: 80%;
    margin: 0 auto;
    color: #fff;
    font-family: PingFang SC;
    font-size: 16px;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
    .infoTitle {
      margin-right: 8px;
    }
    .infoJumpBtn {
      color: #5487ff;
      font-family: PingFang SC;
      font-size: 14px;
      display: inline-block;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right: 26px;
      cursor: pointer;
      &:hover {
        color: #326ffd;
      }
    }
  }

  .horizontalScroll {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
    .arrow {
      cursor: pointer;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      background: #122255;
      justify-content: center;
      &:hover {
        background: #1a3380 !important;
      }
      span {
        color: rgba(255, 255, 255, 0.5);
        font-size: 20px !important;
      }
    }

    .scrollItemsContainer {
      overflow: hidden;
      width: 80vw;
      position: relative; /* 设置相对定位，为阴影效果做准备 */
      text-align: left;
      display: inline-block;
      &:after {
        content: ""; /* 伪元素需要内容，即使是空的 */
        position: absolute; /* 绝对定位相对于正方形 */
        right: 0; /* 定位到正方形的右边 */
        top: 0; /* 顶部对齐 */
        display: inline-block;
        width: 60px; /* 伪元素的宽度，即阴影的宽度 */
        height: 100%; /* 伪元素的高度与正方形相同 */
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.5) 100%
        ); /* 从左到右的线性渐变背景 */
      }
      .carousel-inner {
        // gap: 20px;
        transition: transform 0.5s ease;
        display: flex;
      }
      .carousel-item {
        width: 352px;
        margin-right: 32px;
        height: 152px;
        display: inline-block;
        cursor: pointer;
        flex-shrink: 0;
        border-radius: 4px;
        background: linear-gradient(
          135.72deg,
          rgba(11, 82, 203, 0.7) 18.02%,
          rgba(0, 46, 126, 0.7) 77.63%
        );

        &:hover {
          background: linear-gradient(
            135.72deg,
            #0b52cb 18.02%,
            #002e7e 77.63%
          );
          transform: scale(1.08);
        }

        // 添加更多样式

        .rightPart {
          text-align: left;
          flex-direction: column;
          align-items: left;
          justify-content: space-between;
          display: flex;
          padding: 22px 30px;
          .listName {
            color: #fff;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            height: 42px;
            font-weight: 500;
            line-height: normal;
          }
          .infoContent {
            color: #a3a7b9;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 8px 0px;
          }
          .infoBottom {
            color: rgba(255, 255, 255, 0.65);
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            .infoBottomSource {
              margin-right: 16px;
            }
            .infoBottomTime {
              display: inline-block;
              .iconTime {
                position: relative;
                left: -2px;
                top: 1px;
              }
            }
          }
        }
      }
    }
  }
}
