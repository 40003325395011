@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}

:root {
  --14-CN-regular-font-family: "PingFangSC-Regular", Helvetica;
  --14-CN-regular-font-size: 14px;
  --14-CN-regular-font-style: normal;
  --14-CN-regular-font-weight: 400;
  --14-CN-regular-letter-spacing: 0px;
  --14-CN-regular-line-height: 22px;
  --bg-2: rgba(255, 255, 255, 1);
  --fill-2: rgba(242, 243, 245, 1);
  --text-4: rgba(78, 89, 105, 1);
  --text-5: rgba(29, 33, 41, 1);
}
