.leftList {
  box-sizing: border-box;
  height: calc(100vh - 190rem);

  position: absolute;
  width: 297rem;
  // height: 865rem;
  left: 40rem;
  top: 142rem;
  border: 1rem solid #2057ab;
  z-index: 50;
  // &::before {
  //   content: "";
  //   position: absolute;
  //   right: 18rem;
  //   bottom: 22rem;
  //   z-index: 9999;
  //   width: 4rem;
  //   height: 8rem;
  //   display: inline-block;
  //   background-color: #2c3a65;
  // }
  .topBar {
    box-sizing: border-box;

    position: absolute;
    width: 297rem;
    height: 55rem;
    left: 0rem;
    top: 0rem;

    background: linear-gradient(
      360deg,
      rgba(5, 61, 145, 0.539) 0%,
      rgba(5, 41, 96, 0.56) 100%
    );
    border-bottom: 1rem solid #2463c2;
    .topBarTitle {
      position: absolute;
      width: 94rem;
      height: 22rem;
      left: 24rem;
      top: 16rem;
      display: inline-block;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 500;
      font-size: 16rem;
      line-height: 22rem;

      color: #ffffff;

      text-shadow: 0rem 4rem 4rem rgba(0, 0, 0, 0.25);
    }

    .topBarCount {
      position: absolute;
      height: 20rem;
      right: 16rem;
      top: 18rem;
      display: inline-block;

      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 14rem;
      line-height: 20rem;
      /* identical to box height */

      color: #ffffff;
    }
  }

  .iconfont {
    color: #707e92;
    font-size: 18rem !important;
  }
  .rootNode {
    position: absolute;
    width: 272rem;
    height: 42rem;
    left: 12rem;
    top: 70rem;
    box-sizing: border-box;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 16rem;
    line-height: 18rem;
    padding: 12rem;
    border-radius: 2rem;
    cursor: pointer;
    /* identical to box height */
    color: #ffffff;
  }

  .rootNodeActive {
    background: linear-gradient(90.23deg, #1a56b0 0.2%, #02398c 99.86%);
  }

  :global {
    .ant-tree-list {
      padding-top: 4px;
    }
    .ant-tree {
      background: transparent;
      background-color: transparent;
      font-size: 16rem;
      line-height: 24rem !important;
      position: absolute;
      top: 112rem;
    }
    .ant-tree-title {
      position: relative;
      top: 2rem;
    }

    .ant-tree-title {
      color: white;
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;
      height: 42rem;
      line-height: 42rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
      font-weight: 400;
    }

    .ant-tree-node-selected {
      background: none !important;
      background-color: transparent !important;
    }
    .ant-tree-treenode-selected {
      background: linear-gradient(
        90.23deg,
        #1a56b0 0.2%,
        #02398c 99.86%
      ) !important;
    }
    .ant-tree-treenode:hover {
      background: #2057ab66;
    }
    .ant-tree-treenode {
      border-radius: 2rem;
    }
    .ant-tree-switcher-icon {
      color: #b5b5b5;
      position: relative;
      top: 2px;
    }
    @media screen and (max-width: 1520px) {
      .ant-tree-switcher-icon {
        color: #b5b5b5;
        position: relative;
        // top: 8rem;
      }
    }
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14rem;
    line-height: 20rem;
    /* identical to box height */

    color: #3392ff;

    text-shadow: 0rem 0rem 9rem rgba(14, 77, 243, 0.67);
    position: absolute;
    top: 24rem;
    left: 46rem;
  }
  .list {
    display: inline-block;
    padding-right: 7rem;
    width: 280rem;
    margin-left: 12rem;
    height: calc(100% - 120rem);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    .item {
      width: calc(100% - 36rem);
      height: 36rem;
      line-height: 36rem;
      padding: 0rem 12rem;
      padding-right: 0rem;
      margin: 0;
      font-weight: 400;

      cursor: pointer;
      font-size: 14rem;
      /* identical to box height */
      margin-left: 8rem;
      color: #eaeaea;
      &:hover {
        color: #2ac6e7;
      }
    }

    .active {
      color: #eaeaea !important;
      font-weight: 600;

      background: linear-gradient(96.99deg, #3fb9ff 6.19%, #1456ff 79.33%);
    }
  }
}

.leftListCollapse {
  width: 48rem;
  height: 642rem;
  z-index: 50;
  left: 0rem;
  top: 90rem;
  position: absolute;
  background: url("../../image/leftListCollapseBg.svg") no-repeat;

  .openBtn {
    position: absolute;
    left: 26rem;
    top: 298rem;
    display: inline-block;
    cursor: pointer;
    width: 20rem;
    height: 20rem;
    background: url("../../image/common/open.svg") no-repeat;
  }
  .openTitle {
    position: absolute;
    width: 14rem;
    height: 212rem;
    left: 10rem;
    top: 216rem;

    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 600;
    font-size: 14rem;
    line-height: 20rem;

    color: #3392ff;

    text-shadow: 0rem 0rem 9rem #0e4df3;
  }
}

@media screen and (max-width: 1520px) {
  .leftList {
    // height: 780rem;
    top: 168rem;
  }
}
