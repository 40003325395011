.paper {
  width: calc(100% - 172px);
  display: inline-block;
  box-sizing: border-box;
  position: relative;

  .totalBar {
    color: #90959d;
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    height: 28px;
    margin-top: 6px;
    font-weight: 400;
    line-height: normal;
    .sortCt {
      margin-top: -4px;
      float: right;
      display: flex;
      align-content: center;
      justify-content: center;
      .sortLabel {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        line-height: 24px;
        text-align: center;
        margin-right: 4px;
        color: rgba(38, 59, 95, 0.8);
        span {
          vertical-align: middle;
        }
      }
      .sortItem {
        display: flex;
        align-content: center;
        gap: 8px;
        justify-content: left;
        span {
          color: rgba(38, 59, 95, 0.5);
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          padding: 4px 8px;
          cursor: pointer;
          &:hover {
            color: rgba(50, 111, 253, 1);
          }
        }
        .actived {
          background: rgba(50, 111, 253, 0.1);
          border-radius: 4px;
          color: rgba(50, 111, 253, 1) !important;
        }
      }
    }
  }
  .listCt {
    width: 100%;

    .listItem {
      padding: 20px 0px 20px 0px;
      border-bottom: 1px solid rgba(38, 59, 95, 0.1);
      &:first-child {
        padding-top: 8px;
      }
      .topTitle {
        color: #263b5f;
        font-family: PingFang SC;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        &:hover {
          color: #63799d;
        }
      }
      .authors {
        color: #90959d;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        margin: 8px 0;
        font-weight: 400;
        line-height: normal;
      }
      .content {
        color: #263b5f;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .bottomInfo {
        // margin-top: 4px;
        margin-bottom: 8px;

        .bottomCount {
          display: inline-block;
          vertical-align: middle;
          .bottomDetail {
            display: inline-block;
            margin-right: 20px;
            color: #90959d;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            // .label {
            //   font-style: normal;
            //   font-weight: 400;
            //   font-size: 12px;
            //   line-height: 17px;
            //   /* identical to box height */

            //   color: #c8c8c8;
            // }
            // .count {
            //   font-style: normal;
            //   font-weight: 500;
            //   font-size: 12px;
            //   line-height: 17px;
            //   /* identical to box height */

            //   color: #fff;
            // }
          }
        }
      }
    }
  }

  .emptyPart {
    display: inline-block;
    width: 125px;
    margin-top: 120px;
    position: absolute;
    left: 50%;
    transform: translateX(-50% - 172px);

    img {
      width: 125px;
      height: 104px;
    }
    p {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      color: #90959d;
    }
  }

  .footerBar {
    height: 88px;
    width: 100%;
    .pagination {
      margin-right: 23px;
      margin-top: 24px;

      :global {
        .ant-pagination-item {
          border-radius: 2px !important;
          height: 32px !important;
          border: 1px solid transparent !important;
          font-weight: 400;
          font-size: 14px !important;
          background: #f5f6f6 !important;
          &:hover {
            background: #ebf1ff !important;
          }
          /* identical to box height, or 140% */
        }
        .ant-pagination-item-active {
          border-radius: 2px !important;
          color: #fff !important;
          background: #326ffd !important;
        }
        .ant-pagination-item a {
          color: #394a70 !important;

          line-height: 32px !important;
        }
        .ant-pagination-item-active a {
          color: #fff !important;
        }
        .ant-pagination-item-ellipsis {
          color: #d8d8d8 !important;
        }
        .ant-pagination-prev,
        .ant-pagination-next {
          line-height: 32px !important;
          height: 32px !important;
        }
        .ant-pagination-prev .ant-pagination-item-link {
          background: #f5f6f6 !important;
          background-color: #f5f6f6 !important;
          color: #394a70 !important;
          border: 1px solid transparent !important;
          border-radius: 2px !important;
          line-height: 32px !important;
        }

        .ant-pagination-next .ant-pagination-item-link {
          background: #f5f6f6 !important;
          background-color: #f5f6f6 !important;
          color: #394a70 !important;
          border: 1px solid transparent !important;
          line-height: 32px !important;
          border-radius: 2px !important;
        }
        .ant-pagination-next .ant-pagination-item-link .anticon-right {
          color: #394a70 !important;
        }
        .ant-pagination-disabled .ant-pagination-item-link {
          color: #a1aacc !important;
        }
        .ant-pagination-disabled .ant-pagination-item-link .anticon-right {
          color: #a1aacc !important;
        }

        .ant-pagination-options .ant-select-selector {
          background: #061836 !important;
          border-radius: 2px !important;
          height: 32px !important;
          font-weight: 400;
          font-size: 14px !important;
          /* identical to box height, or 140% */

          color: #f5f5f5 !important;

          line-height: 32px !important;
        }
        .ant-pagination-total-text {
          color: #f5f5f5 !important;
          float: left;
        }
        .ant-pagination-options-quick-jumper {
          color: #f5f5f5 !important;
        }
        .ant-pagination-options-quick-jumper input {
          background: #061836 !important;
          color: #f5f5f5 !important;
          border-radius: 2px !important;
          border: 1px solid #3872ca !important;
        }
      }
    }
  }
}
