.header {
  height: 70px;
  padding: 21px 32px;
  border-bottom: 1px solid #e0e2e5;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  background-color: #fbfcff;
  position: relative;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    width: 34px;
    height: 34px;
    vertical-align: middle;
    line-height: 70px;
    margin-right: 24px;
    cursor: pointer;
    display: inline-block;
  }
  .name {
    color: #263b5f;
    line-height: 70px;
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-right: 12px;
    vertical-align: middle;
    display: inline-block;
    line-height: normal;
    max-width: calc(40vw);
  }
  @media screen and (max-width: 1300px) {
    .name {
      max-width: calc(30vw);
    }
  }
  @media screen and (min-width: 2000px) {
    .name {
      max-width: calc(60vw);
    }
  }
  .tag {
    display: inline-flex;
    vertical-align: middle;
    padding: 2px 8px;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
  }
  .tab {
    display: inline-block;
    position: absolute;
    right: 120px;
    .tabItem {
      cursor: pointer;
      display: inline-block;
      margin-right: 60px;
      .tabItemName {
        color: #263b5f;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .tabItemIcon {
        color: #263b5f;
        margin-right: 4px;
      }
    }
  }
  .loginBox {
    display: flex;
    align-items: center;
    justify-content: center;
    .avatar {
      width: 28px;
      height: 28px;
      display: inline-block;
      border-radius: 28px;
      vertical-align: middle;
      margin-right: 8px;
    }
    .loginIcon {
      color: #263b5f;
      font-size: 28px;
      margin-right: 12px;
    }
    .statusText {
      color: #263b5f;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      cursor: pointer;
      line-height: normal;
    }
  }
}
.headerLogin {
  .dropdown {
    min-width: 106px;
  }
  .accountInfo {
    display: flex;
    width: 288px;
    align-items: center;
    padding: 16px;
    color: rgba(46, 46, 46, 0.88);
    background-color: #f4f5f7;
    vertical-align: middle;
    cursor: pointer;
    box-sizing: border-box;
  }

  .avatar {
    width: 64px;
    vertical-align: middle;
    height: 64px;
    margin-right: 12px;
    border-radius: 50%;
    object-fit: cover;
  }
  .item {
    height: 40px;
    padding: 12px 16px;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    &:hover {
      background: rgba(27, 96, 233, 0.1);
    }

    .icon {
      margin-right: 6px;
      vertical-align: middle;
      font-size: 16px;
    }
  }

  :global {
    .ant-popover-inner {
      min-width: 106px;
      border-radius: 8px;
      box-shadow: 0 2px 13px #378dd55e;
      padding: 10px 0;
    }
  }
}
.unLoginBox {
  width: 95px;
  height: 36px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  background: rgba(27, 96, 233, 1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #5583ed;
  }
  .statusText {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
  }
}
