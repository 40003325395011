.PersonDetailPart {
  position: absolute;
  width: 350rem;
  min-width: 320px;
  z-index: 50;
  height: 80vh;
  min-height: 500rem;
  display: flex;
  flex-flow: column;
  right: 40rem;
  top: 128rem;
}

.hide {
  display: none;
}
