.PaperWin {
  box-sizing: border-box;
  width: 100%;
  background: linear-gradient(
    271.01deg,
    rgba(0, 17, 48, 0.8) 82.36%,
    rgba(0, 17, 48, 0.56) 109.1%
  );
  flex: 1;
  margin-top: 60rem;
  position: relative;
  z-index: 99;

  .list {
    margin: 0 auto;
    margin-top: 60rem;
    height: calc(100% - 80rem);
    z-index: 20;
    position: absolute;
    display: inline-block;
    width: 310rem;
    padding: 0 8rem;
    overflow-x: hidden;
    overflow-y: hidden;
    &:hover {
      overflow-y: auto;
    }
    .listItem {
      display: inline-block;
      width: 310rem;
      padding: 16rem 20rem;
      box-sizing: border-box;
      &:nth-child(odd) {
        background: rgba(53, 87, 143, 0.1);
      }
      &:hover {
        background: #052f67 !important;
      }
      // cursor: pointer;

      .listName {
        font-style: normal;
        font-weight: 500;
        font-size: 14rem;
        line-height: 20rem;
        cursor: pointer;
        color: #ffffff;
        &:hover {
          color: #5d8dd3;
        }
      }
      .authors {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        /* identical to box height */

        color: #b5b5b5;
        margin-top: 4rem;
        margin-bottom: 8rem;
      }
      .countDesc {
        font-weight: 400;
        font-size: 12rem;
        line-height: 17rem;
        /* identical to box height */

        color: #c1c1c1;
      }

      .bottomDetail {
        display: inline-block;
        margin-right: 12rem;
        .label {
          font-style: normal;
          font-weight: 400;
          font-size: 12rem;
          line-height: 17rem;
          /* identical to box height */

          color: #c8c8c8;
        }
        .count {
          font-style: normal;
          font-weight: 500;
          font-size: 12rem;
          line-height: 17rem;
          /* identical to box height */

          color: #fff;
        }
      }
    }
  }
}

.title {
  position: absolute;
  width: 92rem;
  height: 28rem;
  left: 40rem;
  top: 20rem;
}
.hide {
  display: none;
  transition: all 0.6s linear;
}
