.container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0rem;
  top: 0rem;

  .legend {
    // background: rgba(9, 36, 78, 0.65);
    position: fixed;
    border-radius: 4rem;
    left: 40rem;
    z-index: 50;
    width: 226rem;
    height: 36rem;
    display: inline-block;
    bottom: 32rem;
    display: flex;
    justify-content: space-around;
    align-items: center;

    text-align: center;
    .org_label,
    .scholar_label,
    .industry_label {
      font-weight: 400;
      font-size: 12rem;
      line-height: 17rem;
      display: inline-block;
      /* identical to box height */
      position: relative;
      color: #ffffff;
      padding-left: 20rem;
    }

    .industry_label {
      &::before {
        content: "";
        width: 16rem;
        height: 16rem;
        display: inline-block;
        border-radius: 16rem;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(144.46deg, #91a9ff 4.77%, #384cff 88.28%);
      }
    }
    .org_label {
      &::before {
        content: "";
        width: 16rem;
        border-radius: 16rem;
        height: 16rem;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(144.46deg, #af8eff 4.77%, #6218ff 88.28%);
      }
    }
    .scholar_label {
      &::before {
        content: "";
        width: 16rem;
        height: 16rem;
        display: inline-block;
        border-radius: 16rem;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(144.46deg, #fff5ae 4.77%, #ffb342 88.28%);
      }
    }
  }

  .filedRightPart {
    position: absolute;
    width: 350rem;
    min-width: 320px;
    z-index: 50;
    height: 80vh;
    min-height: 500rem;
    display: inline-block;
    right: 40rem;
    top: 128rem;
  }
}
