.ScholarPart {
  box-sizing: border-box;
  position: relative;
  flex: 1;
  width: 100%;
  height: calc(100vh - 464px);
  margin-top: 12px;
  // border-top: 1px solid #e0e2e5;

  .list {
    margin-top: 8px;
    margin-left: 12px;
    z-index: 20;
    display: inline-block;
    width: calc(100% - 16px);
    height: calc(100%);
    overflow-y: hidden;
    overflow-x: hidden;
    &:hover {
      overflow-y: auto;
    }
    .listItem {
      width: 100%;
      padding: 14px 16px;
      padding-right: 0;
      display: flex;

      // &:nth-child(odd) {
      //   background: rgba(53, 87, 143, 0.1);
      // }
      // &:hover {
      //   background: #052f67 !important;
      // }
      .listImg {
        display: inline-block;
        height: 48px;
        cursor: pointer;
        margin: 0 auto;
        margin-right: 16px;
        vertical-align: middle;
        width: 48px;
        border-radius: 48px;
        object-fit: cover;
      }

      .rightPart {
        display: inline-block;
        flex: 1;
        vertical-align: middle;
        text-align: left;

        .listName {
          color: #263b5f;
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          cursor: pointer;
          line-height: normal;
          &:hover {
            color: #5d8dd3;
          }
        }
        .countDesc {
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          /* identical to box height */

          color: #c1c1c1;
        }

        .bottomDetail {
          display: inline-block;
          margin-right: 12px;
          margin-top: 4px;
          .label {
            color: #90959d;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .count {
            color: #90959d;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .listorg {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          /* identical to box height */

          color: #b5b5b5;
          margin-top: 2px;
        }
      }
    }
  }
}

.hide {
  display: none;
}
