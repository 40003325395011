.secondPartCt {
  width: 100vw;
  background: url(./image/partMiddleBg.png) no-repeat;
  background-size: cover;
}
.secondPart {
  width: 86vw;
  max-width: 1500px;
  height: 800px;
  position: absolute;
  left: 50%;
  height: 50%;
  transform: translateX(-50%) translateY((-50%));
  display: flex;
  align-items: center;
  justify-content: space-between;

  .leftPart {
    width: 30%;

    .title {
      color: #fff;

      font-family: PingFang SC;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 148.523%; /* 71.291px */
    }
    .desc {
      color: #fff;

      font-family: PingFang SC;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%; /* 43.2px */
      margin: 24px 0;
    }
    .btn {
      display: inline-flex;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
      font-family: PingFang SC;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%; /* 43.2px */
      background: linear-gradient(127deg, #427bff 24.03%, #123bcb 83.87%);
      &:hover {
        border-radius: 4px;
        background: linear-gradient(
          127deg,
          rgba(66, 123, 255, 0.8) 24.03%,
          rgba(18, 59, 203, 0.8) 83.87%
        );
      }
    }
  }
  .videoPart {
    width: 64%;
    margin-left: 2%;
    height: 800px;
    display: flex;
    align-items: center;
    .video {
      width: 100%;
      border-radius: 16px;
      box-shadow: 0px 4px 4px 0px rgba(3, 30, 66, 0.5);
      display: inline-block;
    }
  }
}

.threePartTop {
  width: 1200px;
  top: 120px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  .subTitle {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
  }
}

.threePartMiddle {
  width: 1200px;
  position: absolute;
  top: 280px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
}
.threePartBottom {
  position: absolute;
  text-align: center;
  height: 260px;
  top: calc(100vh - 197px);
  width: 100vw;
  overflow: hidden;
  .threePartGuide {
    background: url(./image/homeBottom.png) no-repeat;
    background-size: cover;
    width: 100vw;
    height: 197px;
    margin-bottom: 20px;

    .threePartGuideTop {
      padding: 0 200px;
      height: 197px;
      align-items: center;
      justify-content: space-between;
      display: flex;
      margin-bottom: 20px;
      .threePartLeft {
        text-align: left;
      }
    }
  }
}
.goGuide {
  border: 2px solid rgba(255, 255, 255, 1);

  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.7);
    background: rgba(26, 76, 201, 0.7);
  }
}
@media screen and (max-width: 1520px) {
  .secondPart {
    transform: translateX(-50%) translateY((-50%)) scale(0.8);
  }
  .threePartMiddle {
    top: 180px;

    transform: translateX(-50%) scale(0.7);
  }
}
@media screen and (min-width: 2000px) {
  .secondPart {
    transform: translateX(-50%) translateY((-50%)) scale(1.2);
  }
  .threePartMiddle {
    transform: translateX(-50%) scale(1.2);
    top: 340px;
  }
}
@media screen and (max-height: 1100px) {
  .threePartBottom {
    top: calc(100vh - 100px);
    width: 100vw;
    height: 100px;
    .threePartGuide {
      width: 100vw;
      height: 100px;
      margin-bottom: 20px;
      .threePartGuideTop {
        padding: 0 60px;
        transform: scale(0.7);
        height: 100px;
      }
    }
  }
}
