.IndustryPart {
  box-sizing: border-box;
  position: relative;
  flex: 1;
  width: 100%;
  height: calc(100vh - 300px);
  .list {
    margin: 0 auto;
    z-index: 20;
    position: absolute;
    display: inline-block;
    overflow-y: hidden;
    height: calc(100%);

    &:hover {
      overflow-y: auto;
    }
    .listItem {
      display: inline-block;
      padding: 16px 0px;
      vertical-align: top;
      &:first-child {
        padding-top: 0px;
      }
      // cursor: pointer;
      .listImg {
        display: inline-block;
        cursor: pointer;
        height: 48px;
        vertical-align: middle;
        width: 76px;
        border-radius: 4px;
        margin-right: 14px;
        // border: 1px solid #fff;
      }
      .rightPart {
        display: inline-block;
        width: 252px;
        height: 48px;
        vertical-align: middle;
        min-width: 200px;

        .listName {
          color: #263b5f;
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          width: 100%;
          display: inline-block;
          font-weight: 500;
          cursor: pointer;
          line-height: normal;
          &:hover {
            color: #326ffd;
          }
        }
        .countDesc {
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          /* identical to box height */

          color: #90959d;
        }
      }
    }
  }
}

.hide {
  display: none;
  transition: all 0.6s linear;
}
