.FieldDetailPart {
  position: absolute;
  width: 315rem;
  height: 70vh;
  min-height: 500rem;
  left: 40rem;
  top: 168rem;
  z-index: 50;
  overflow-y: hidden;
  background: linear-gradient(
    271.01deg,
    rgba(0, 17, 48, 0.8) 82.36%,
    rgba(0, 17, 48, 0.56) 109.1%
  );
  &:hover {
    overflow-y: auto;
  }
  .emptyBox {
    height: 180rem;
    text-align: center;
    .emptyImg {
      width: 82rem;
      height: 82rem;
      margin-top: 30rem;
    }
    .emptyText {
      height: 19px;

      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 12rem;
      /* or 19px */
      display: block;
      color: #b5b5b5;
    }
  }

  .title {
    position: absolute;
    height: 28rem;
    left: 40rem;
    top: 40rem;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 20rem;
    line-height: 34rem;
    color: #AECFFF; 

    &::before {
      content: "";
      width: 8rem;
      border-radius: 8rem;
      height: 8rem;
      display: inline-block;
      position: absolute;
      left: -14rem;
      top: 12rem;
      background: #AECFFF ;
    }
  }

  .detail {
    position: absolute;
    height: 22rem;
    left: 32rem;
    top: 85rem;

    .introduceLable {
      height: 22rem;
      position: relative;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 16rem;
      line-height: 22rem;
      left: 14rem;

      color: #81bafc;
    }

    .introduce {
      width: 251rem;
      margin-top: 12rem;
      display: inline-block;
      min-height: 200rem;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 200;
      font-size: 14rem;
      line-height: 180%;
      /* or 22rem */

      color: #ffffff;
    }

    .relativeLable {
      position: relative;
      height: 22rem;
      left: 14rem;
      display: block;
      margin-top: 24rem;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 16rem;
      line-height: 22rem;

      color: #81bafc;
    }

    .listCt {
      margin-left: -16rem;
      margin-top: 14rem;
    }

    .itemList {
      width: 283rem;
      display: block;
      box-sizing: border-box;
      height: 44rem;
      font-style: normal;
      cursor: pointer;
      font-weight: 500;
      font-size: 14rem;
      line-height: 20rem;
      position: relative;
      /* identical to box height */
      padding: 12rem 16rem;
      &:nth-child(odd) {
        background: rgba(53, 87, 143, 0.15);
      }
      &:hover {
        background: #0f3a77 !important;
      }

      .itemText {
        color: #ffffff;
      }
    }
    .iconfont {
      position: absolute;
      right: 16rem;
      color: rgba(255, 255, 255, 0.2);
    }
  }
}

.hide {
  display: none;
}
