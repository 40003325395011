.FieldWin {
  box-sizing: border-box;
  width: 100%;
  border-radius: 16px;
  padding-bottom: 20px;
  background: #fff;
  // overflow-y: auto;
  height: calc(100vh - 70px);
  padding: 24px 0px 24px 32px;
  &::-webkit-scrollbar {
    display: none;
  }
  &:hover {
    &::-webkit-scrollbar {
      display: inherit;
    }
  }

  .topInfo {
    margin-right: 32px;
    .topTitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      /* identical to box height */

      color: #394a70;
      margin: 0px;
      vertical-align: middle;
    }

    .topDesc {
      color: #263b5f;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      word-break: break-all;
      margin-top: 12px;
      line-height: normal;
    }
  }

  .tags {
    margin-top: 8px;
    margin-right: 32px;

    .tagsItem {
      display: inline-block;
      color: #767582;
      margin-right: 8px;
      color: #90959d;
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      padding: 2px 8px;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      line-height: 21px;
      flex-shrink: 0;
      border-radius: 2px;
      background: rgba(144, 149, 157, 0.1);
      margin-bottom: 8px;
    }
  }

  .authors {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    max-width: 100%;
    margin-top: 1px;
    line-height: 20px;
    /* identical to box height */
    color: #767582;

    .authorItem {
      color: #767582;
      font-weight: 400 !important;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .abstract {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
    color: #263b5f;
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    margin-bottom: 8px;
  }
  .countItem {
    display: inline-block;
    max-width: 300px;
    margin-right: 20px;
    margin-top: 12px;
    .countLabel {
      color: #90959d;
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      margin-right: 4px;
      letter-spacing: 0em;
    }
    .countNum {
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
    }
    .countNumActive {
      color: rgba(38, 59, 95, 0.8);
    }
  }

  .footer {
    .footerBtn {
      width: 100px;
      border-radius: 4px;
      display: inline-block;
      margin-top: 12px;
      height: 36px;
      margin-right: 20px;
      text-align: center;
      line-height: 34px;
      background-color: #eaf0fe;
      color: #326ffd;
      cursor: pointer;
      &:hover {
        background-color: rgba(50, 111, 253, 0.2);
      }
      .icon {
        position: relative;
        margin-right: 4px;
        vertical-align: middle;
      }
      .btntext {
        vertical-align: middle;
      }
    }
  }

  .labelTitle {
    color: #263b5f;
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 24px;
    margin-bottom: 10px;
  }
}
