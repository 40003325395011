.legend {
  position: absolute;
  width: 46px;
  display: inline-block;
  top: 18px;
  left: 20px;
  z-index: 99;
  .name {
    margin-bottom: 6px;
  }
  .circle-legend {
    vertical-align: middle;
    font-size: 12px;
    display: inline-block;
    margin-right: 8px;
    width: 12px;
    height: 12px;
    border-radius: 12px;
  }
  .text {
    vertical-align: middle;
    font-size: 12px;
  }
}
