.company {
  width: 1517rem;
  // height: 865rem;
  height: calc(100vh - 190rem);

  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  top: 142rem;
  left: 336rem;
  background: rgba(12, 31, 64, 0.75);
  border-right: 1rem solid #2057ab;
  border-bottom: 1rem solid #2057ab;
  padding-bottom: 60px;
  .emptyPart {
    display: inline-block;
    margin-top: 200rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    img {
      width: 160rem;
    }
    p {
      font-family: PingFang SC;
      font-size: 14rem;
      font-weight: 400;
      line-height: 24rem;
      letter-spacing: 0rem;
      text-align: center;
      color: #90959d;
    }
  }
  .topBar {
    height: 56rem;
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(
      360deg,
      rgba(5, 61, 145, 0.2695) 0%,
      rgba(5, 41, 96, 0.28) 100%
    );
    border: 1rem solid #2463c2;
    .title {
      position: absolute;
      height: 22rem;
      left: 32rem;
      top: 17rem;

      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 500;
      font-size: 16rem;
      line-height: 22rem;

      color: #ffffff;

      text-shadow: 0rem 4rem 4rem rgba(0, 0, 0, 0.25);
    }
    .sortCt {
      display: flex;
      align-items: center;
      width: 670rem;
      height: 56rem;
      position: absolute;
      right: 26rem;
      top: 0rem;

      .iconfont {
        font-size: 12rem;
      }

      .checkBox {
        display: inline-block;
        width: 120rem;
        position: absolute;
        right: 442rem;
        top: 18rem;
        height: 32rem;

        .checkBoxText {
          color: #fff;
          font-style: normal;
          font-weight: 400;
          font-size: 14rem;
          line-height: 20rem;
          vertical-align: middle;
        }
        .checkBoxImg {
          width: 12rem;
          margin-right: 8rem;
          height: 12rem;
          vertical-align: middle;
          cursor: pointer;
          display: inline-block;
          border: 1rem solid #ffffff;
          box-sizing: border-box;
          /* Inside auto layout */

          flex: none;
          order: 0;
          flex-grow: 0;

          &:hover {
            border: 2rem solid #3392ff;
          }
        }

        .checkBoxImgActive {
          background: url("../../image/paper/checkbox-select.svg") no-repeat;
          vertical-align: middle;
          margin-right: 8rem;
          width: 12rem;
          display: inline-block;
          height: 12rem;
          border-radius: 4rem;
          background-size: contain;
          border: none;
        }
      }

      .selectBox {
        display: flex;
        align-items: center;
        width: 180rem;
        position: absolute;
        right: 274rem;

        :global {
          .ant-select-selector {
            height: 32rem;
          }
          .ant-select-selection-item {
            line-height: 30rem;
            font-size: 14rem;
            color: #ffffff !important;
          }
        }
      }

      .searchBox {
        position: absolute;
        right: 0rem;
        top: 12rem;
        border: 1rem solid #1b55ab !important;
        border-radius: 2rem !important;
        background-color: transparent !important;
        height: 32rem;
        width: 250rem;
        &:hover {
          border: 1rem solid #3284ff !important;
        }
        .iconfont {
          color: #4972ae !important;
          font-size: 14rem;
          cursor: pointer;
          &:hover {
            color: #fff !important;
          }
        }

        :global {
          .ant-input {
            background-color: #122252 !important;
            font-size: 14rem;
            color: #fff !important;

            &::placeholder {
              color: #4972ae !important;
            }
          }
        }
      }
    }
  }
  .closeImg {
    margin-top: 5rem;
    width: 12rem;
    height: 12rem;
    &:hover {
      opacity: 0.7;
    }
  }
  .listCt {
    width: 100%;
    margin-top: 58rem;
    height: calc(100% - 56rem - 3px);

    overflow-y: scroll;

    .listItem {
      padding: 28rem 32rem;

      &:nth-child(odd) {
        background: #152854;
      }

      .topInfo {
        .topTitle {
          display: flex;
          align-items: center;
          justify-content: left;
          .titleText {
            font-weight: 600;
            font-size: 18rem;
            line-height: 22rem;
            /* identical to box height */
            margin: 0rem;
            cursor: pointer;
            margin-right: 12rem;
            color: #ffffff;
            display: inline-block;
            vertical-align: middle;
            &:hover {
              color: rgba(255, 255, 255, 0.7);
            }
          }
        }

        .tabNormal {
          font-family: PingFang SC;
          font-size: 12rem;
          font-weight: 500;
          line-height: 20rem;
          letter-spacing: 0em;
          text-align: left;
          vertical-align: middle;
          display: inline-flex;
          padding: 0rem 8rem;
          align-items: center;
          border-radius: 2rem;
          border: 1rem solid #90959d;
          color: #90959d;
        }

        .tabActive {
          font-family: PingFang SC;
          font-size: 12rem;
          font-weight: 500;
          line-height: 20rem;
          letter-spacing: 0em;
          color: #00b42a;
          text-align: left;
          display: inline-flex;
          padding: 0rem 8rem;
          align-items: center;
          border-radius: 2rem;
          border: 1rem solid #00b42a;
        }
      }
      .infoPart {
        display: block;
        margin-top: 8rem;

        .info {
          color: #b5b5b5;
          font-family: PingFang SC;
          font-size: 14rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-right: 180rem;
        }
        .infoActive {
          color: #5487ff;
          cursor: pointer;
          &:hover {
            color: #326ffd;
          }
        }
      }
      .region {
        font-weight: 500;
        vertical-align: middle;
        font-size: 14rem;
        line-height: 20rem;
        margin-top: 2rem;
        /* identical to box height */
        vertical-align: -3rem;
        color: #b5b5b5;
        margin-left: 8rem;
      }

      .content {
        font-weight: 400;
        font-size: 14rem;
        line-height: 20rem;
        margin-top: 3rem;
        color: #b5b5b5;
      }
      .tagList {
        display: inline-block;
        margin-top: 6rem;
        width: 820rem;
        vertical-align: middle;
        .tag {
          font-style: normal;
          font-weight: 400;
          margin-bottom: 4rem;
          font-size: 12rem;
          line-height: 16rem;
          color: rgba(255, 255, 255, 0.84);

          display: inline-block;
          /* identical to box height */
          background: #124ca0;
          padding: 2rem 8rem;
          margin-right: 8rem;
        }
      }

      .bottomInfo {
        margin-top: 6rem;

        .bottomCount {
          display: inline-block;
          vertical-align: middle;
          .bottomDetail {
            display: inline-block;
            margin-right: 20rem;
            .label {
              font-style: normal;
              font-weight: 400;
              font-size: 12rem;
              line-height: 17rem;
              /* identical to box height */

              color: #c8c8c8;
            }
            .count {
              font-style: normal;
              font-weight: 500;
              font-size: 12rem;
              line-height: 17rem;
              /* identical to box height */

              color: #fff;
            }
          }
        }
      }
    }
  }

  .footerBar {
    position: absolute;
    bottom: 0px;
    border-top: 1px solid #2057ab;
    left: 0px;
    height: 60px;
    width: 100%;
    .pagination {
      float: right;
      margin-right: 23px;
      margin-top: 14px;
      :global {
        .ant-empty-description {
          color: #fff;
        }
      }
    }

    .totalBar {
      position: absolute;
      width: 153px;
      height: 20px;
      left: 32px;
      top: 20px;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height */

      color: #b5b5b5;
    }
  }
}
@media screen and (max-width: 1520px) {
  .company {
    // height: 780rem;
    top: 168rem;
  }
}
