.OrgWin {
  box-sizing: border-box;
  width: 100%;
  border-radius: 16px;
  text-align: center;
  padding-bottom: 20px;
  background: #fff;
  .jumpBtn {
    position: absolute;

    right: 16px;
    top: 24px;

    font-family: PingFang SC;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height */

    color: #3284ff;
    cursor: pointer;
  }

  .listImg {
    display: inline-block;
    width: 120px;
    height: 76px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 32px;
  }

  .listName {
    cursor: pointer;
    margin-top: 12px;
    padding: 0 32px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #394a70;
    &:hover {
      color: #326ffd;
    }
  }
  .splitLine {
    width: 332px;
    height: 1px;
    position: relative;
    top: 14px;
    margin: 0 auto;
    // border-top: 1px solid #e0e2e5;
    background-color: #e0e2e5;
  }
  .countPart {
    width: 332px;
    height: 70px;
    margin-top: 12px;
    display: inline-block;
    border-top: 1px solid #dcdff3;
    border-bottom: 1px solid #dcdff3;

    .countItem {
      display: inline-block;
      width: 150px;
      height: 70px;
      position: relative;
      .count {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        margin-top: 12px;
        line-height: 20px;
        text-align: center;
        /* identical to box height */
        margin-bottom: 8px;
        color: #394a70;
      }
      .countType {
        font-family: PingFang SC;
        font-style: normal;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        /* identical to box height */

        color: #767582;
      }

      &:nth-child(1) {
        &::after {
          content: "";
          position: absolute;
          height: 22px;
          width: 1px;
          right: 0px;
          top: 24px;

          background: #dcdff3;
        }
      }
    }
  }

  .title {
    position: absolute;
    height: 17px;
    left: 25px;
    top: 238px;
    padding-left: 3px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height */

    color: #394a70;
  }
  .chart {
    width: 332px;
    height: 130px;
    margin-top: 24px;
    display: inline-block;
  }
  .footer {
    border-top: 1px solid #dcdff3;
    width: 332px;
    display: flex;
    margin-top: 12px;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    .footerBtn {
      width: 140px;
      margin-top: 12px;
      height: 36px;
      line-height: 34px;
      background-color: #eaf0fe;
      color: #326ffd;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: rgba(50, 111, 253, 0.2);
      }
      .icon {
        position: relative;
        margin-right: 4px;
        vertical-align: middle;
      }
      .btntext {
        vertical-align: middle;
      }
    }
  }
}
