.expandable-text {
  position: relative;
  max-height: 22.5em; // 假设每行高度为1.8em，两行则是3.6em
  overflow: hidden;
  line-height: 1.5em;
  margin-top: 12px;
  // transition: max-height 0.3s ease;
  color: #263b5f;
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  margin-bottom: 12px;
  &.expanded {
    max-height: 100%; // 展开时移除max-height限制
  }
}

.expandable-btn {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #2452bf;
  cursor: pointer;
  position: absolute;
  bottom: -2px;
  right: 0;
  background-color: #fff;
  border: none;
  padding: 2px 10px;
  padding-left: 0px;
  padding-right: 0px;

  cursor: pointer;
  &:hover {
    color: #326ffd;
  }
  &::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    left: -20px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.35) 0%,
      #ffffff 71.59%
    );
    display: inline-block;
  }
}
.collapse-link {
  color: #2452bf;
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
  font-weight: 400;
  line-height: normal;
  word-break: normal;
}
.expandable-icon {
  color: #2452bf;
  font-size: 12px;
}
