@font-face {
  font-family: "iconfont"; /* Project id 3801447 */
  src: url('iconfont.woff2?t=1706690189328') format('woff2'),
       url('iconfont.woff?t=1706690189328') format('woff'),
       url('iconfont.ttf?t=1706690189328') format('truetype'),
       url('iconfont.svg?t=1706690189328#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sousuo2:before {
  content: "\e651";
}

.icon-duihua1:before {
  content: "\e652";
}

.icon-shiyongshouce:before {
  content: "\e650";
}

.icon-caozuozhiyin:before {
  content: "\e64f";
}

.icon-fulu:before {
  content: "\e64d";
}

.icon-changjianwenti:before {
  content: "\e64e";
}

.icon-Frame:before {
  content: "\e64c";
}

.icon-xiangqing:before {
  content: "\e64b";
}

.icon-paixu:before {
  content: "\e649";
}

.icon-time:before {
  content: "\e64a";
}

.icon-fuzhichenggong:before {
  content: "\e644";
}

.icon-zhongxinshengcheng:before {
  content: "\e645";
}

.icon-fuzhi:before {
  content: "\e646";
}

.icon-cai:before {
  content: "\e647";
}

.icon-zan:before {
  content: "\e648";
}

.icon-zhuanli:before {
  content: "\e63f";
}

.icon-xuezhe1:before {
  content: "\e640";
}

.icon-jigou1:before {
  content: "\e641";
}

.icon-qiye:before {
  content: "\e642";
}

.icon-lunwen1:before {
  content: "\e643";
}

.icon-right:before {
  content: "\e63d";
}

.icon-left:before {
  content: "\e63e";
}

.icon-tuichudenglu:before {
  content: "\e63b";
}

.icon-people:before {
  content: "\e63c";
}

.icon-lunwen:before {
  content: "\e638";
}

.icon-xuezhe:before {
  content: "\e639";
}

.icon-jigou:before {
  content: "\e63a";
}

.icon-lingyu:before {
  content: "\e636";
}

.icon-chakan1:before {
  content: "\e637";
}

.icon-jujiao:before {
  content: "\e635";
}

.icon-lianjie:before {
  content: "\e633";
}

.icon-xiangxia:before {
  content: "\e634";
}

.icon-quanping:before {
  content: "\e630";
}

.icon-bangzhu:before {
  content: "\e631";
}

.icon-shouqi:before {
  content: "\e632";
}

.icon-fasong:before {
  content: "\e62e";
}

.icon-cankaoziyuan:before {
  content: "\e62f";
}

.icon-duihua:before {
  content: "\e62a";
}

.icon-shujuku:before {
  content: "\e62b";
}

.icon-sousuo1:before {
  content: "\e62c";
}

.icon-huanyipi:before {
  content: "\e62d";
}

.icon-back:before {
  content: "\e629";
}

.icon-close:before {
  content: "\e628";
}

.icon-changan:before {
  content: "\e624";
}

.icon-shuangji:before {
  content: "\e625";
}

.icon-gunlun:before {
  content: "\e626";
}

.icon-danji:before {
  content: "\e627";
}

.icon-caret-right:before {
  content: "\e622";
}

.icon-caret-down:before {
  content: "\e623";
}

.icon-sousuo:before {
  content: "\e621";
}

.icon-tuichuquanping:before {
  content: "\e620";
}

.icon-youjian:before {
  content: "\e61b";
}

.icon-caozuotishi:before {
  content: "\e61c";
}

.icon-zhongxinzairu:before {
  content: "\e61d";
}

.icon-quanpingzhanshi:before {
  content: "\e61e";
}

.icon-shiyingquanping:before {
  content: "\e61f";
}

.icon-tishi1:before {
  content: "\e61a";
}

.icon-tishi:before {
  content: "\e619";
}

.icon-fangda:before {
  content: "\e611";
}

.icon-suoxiao:before {
  content: "\e613";
}

.icon-zhongzhi:before {
  content: "\e617";
}

.icon-shiyingpingmu:before {
  content: "\e618";
}

.icon-renwu1:before {
  content: "\e602";
}

.icon-ziyuan1:before {
  content: "\e603";
}

.icon-suanfa1:before {
  content: "\e601";
}

.icon-tupu1:before {
  content: "\e60f";
}

.icon-tupu:before {
  content: "\e612";
}

.icon-renwu:before {
  content: "\e614";
}

.icon-ziyuan:before {
  content: "\e615";
}

.icon-suanfa:before {
  content: "\e616";
}

.icon-more:before {
  content: "\e60e";
}

.icon-add2:before {
  content: "\e610";
}

.icon-exit:before {
  content: "\e60d";
}

.icon-up:before {
  content: "\e60c";
}

.icon-down:before {
  content: "\e60b";
}

.icon-model:before {
  content: "\e600";
}

.icon-a-Frame2:before {
  content: "\e609";
}

.icon-menu-fold:before {
  content: "\e608";
}

.icon-menu-fold-copy:before {
  content: "\e60a";
}

.icon-a-Frame1:before {
  content: "\e604";
}

.icon-a-blue1:before {
  content: "\e607";
}

.icon-fold:before {
  content: "\e605";
}

.icon-fold-1:before {
  content: "\e606";
}

