.PersonWin {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  height: 400rem;
  background: linear-gradient(
    271.01deg,
    rgba(0, 17, 48, 0.8) 82.36%,
    rgba(0, 17, 48, 0.56) 109.1%
  );
  .listImg {
    border: 2rem solid #296dd3;
    display: inline-block;
    width: 60rem;
    border-radius: 60rem;
    height: 60rem;
    position: absolute;
    left: 138rem;
    top: 36rem;
    cursor: pointer;
    object-fit: cover;
  }

  .listName {
    position: absolute;
    width: 300rem;
    left: 20rem;
    top: 104rem;
    cursor: pointer;

    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 500;
    font-size: 16rem;
    line-height: 22rem;
    text-align: center;
    color: #ffffff;
    &:hover {
      color: #5d8dd3;
    }
  }

  .subName {
    position: absolute;
    width: 300rem;
    height: 17rem;
    left: 20rem;

    top: 130rem;
    text-align: center;

    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 12rem;
    line-height: 17rem;
    /* identical to box height */

    color: #c1c1c1;
  }

  .countPart {
    position: absolute;
    width: 300rem;
    height: 70rem;
    left: 20rem;
    top: 155rem;
    display: inline-block;
    border-top: 1rem solid rgba(235, 235, 235, 0.15);
    border-bottom: 1rem solid rgba(235, 235, 235, 0.15);

    .countItem {
      display: inline-block;
      width: 100rem;
      height: 70rem;
      position: relative;
      .count {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 500;
        font-size: 14rem;
        margin-top: 12rem;
        line-height: 20rem;
        text-align: center;
        /* identical to box height */
        margin-bottom: 8rem;
        color: #ffffff;
      }
      .countType {
        font-family: "PingFang SC";
        font-style: normal;
        text-align: center;
        font-weight: 400;
        font-size: 12rem;
        line-height: 17rem;
        /* identical to box height */

        color: #c1c1c1;
      }

      &:nth-child(2) {
        &::before {
          content: "";
          position: absolute;
          height: 22rem;
          width: 1rem;
          left: 0rem;
          top: 24rem;

          background: rgba(235, 235, 235, 0.15);
        }

        &::after {
          content: "";
          position: absolute;
          height: 22rem;
          width: 1rem;
          right: 0rem;
          top: 24rem;

          background: rgba(235, 235, 235, 0.15);
        }
      }
    }
  }

  .title {
    position: absolute;
    height: 17rem;
    left: 25rem;
    top: 238rem;
    padding-left: 3rem;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 500;
    font-size: 12rem;
    line-height: 17rem;
    /* identical to box height */

    color: #ffffff;

    &::after {
      content: "";

      position: absolute;
      width: 2rem;
      height: 14rem;
      left: -2rem;
      top: 1rem;

      background: #3284ff;
    }
  }
  .chart {
    position: absolute;
    width: 300rem;
    height: 120rem;
    left: 24rem;
    top: 266rem;
    display: inline-block;
  }
}
