.title {
  position: absolute;
  width: 220rem;
  height: 22rem;
  left: 0rem;
  top: 0rem;
  z-index: 18;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 16rem;
  line-height: 22rem;

  color: #ffffff;
}
.bg {
  position: absolute;
  top: -8rem;
  z-index: 8;
  left: -28rem;
  display: inline-block;
  width: 212rem;
  height: 32rem;
  background-position: center;
  background: url(../../image/gkx/titleBg.svg) no-repeat;
  background-size: contain;
}
