@keyframes myRotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
.EntryPage {
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  height: 100%;
  .wapperTitle {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .strongText {
      color: #263b5f;
      text-align: center;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      vertical-align: middle;
      line-height: normal;
    }

    .titleImg {
      vertical-align: middle;
      margin-right: 8px;
      width: 24px;
      height: 24px;
      display: inline-block;
    }
    .weakText {
      height: 24px;
      float: right;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      cursor: pointer;

      color: #326ffd;
    }
  }

  .exampleList {
    .exampleItem {
      box-sizing: border-box;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      margin-bottom: 20px;
      width: 100%;
      cursor: pointer;
      /* Neutral/gray 800 */

      background: #ffffff;
      /* Neutral/gray 500 */

      border: 1px solid #282d45;
      border-radius: 4px;

      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 150% */

      /* Neutral/gray 300 */

      color: rgba(38, 59, 95, 0.7);
      border-radius: 4px;
      border: 1px solid rgba(38, 59, 95, 0.2);
      background: #fff;
      &:hover {
        color: rgba(38, 59, 95, 1);
        border-radius: 4px;
        border: 1px solid rgba(38, 59, 95, 0.5);
        background: #fff;
      }
    }
  }

  .wapperBtn {
    margin-top: 20px;
    .sendBtn {
      padding: 8px 36px;
      box-sizing: border-box;
      position: relative;
      width: 105px;
      cursor: pointer;
      border: 0px !important;
      background: linear-gradient(230.75deg, #6d0cfe 17.98%, #b235ff 84.06%);
      border-radius: 4px;

      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.005em;
      margin-right: 16px;
      color: #ffffff;
    }
    .helpText {
      height: 20px;

      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height */

      color: #626e8a;
    }
  }
}
.wapperTop {
  width: 100%;
  margin-top: 40px;
  overflow-x: hidden;
  position: relative;
  height: calc(100vh - 240px);
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .wapperTopCardGradientTop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    z-index: 999;
    background: linear-gradient(
      180deg,
      rgba(248, 249, 252, 1) 0%,
      rgba(248, 249, 252, 0) 100%
    );

    pointer-events: none; /* 确保遮罩不会阻止滚动 */
  }
  .wapperTopCardGradientBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    z-index: 999;
    background: linear-gradient(
      0deg,
      rgba(235, 241, 254, 1) 0%,
      rgba(235, 241, 254, 0) 100%
    );

    pointer-events: none; /* 确保遮罩不会阻止滚动 */
  }

  .wapperTopCard {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    max-height: 520px;
    gap: 16px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.wapperSmallTopCt {
  padding: 24px;
}
.wapperSmallTop {
  // margin: 24px;
  border-radius: 8px;
  padding: 16px;
  background: linear-gradient(
    102deg,
    rgba(229, 244, 255, 0.4) 2.25%,
    rgba(232, 239, 255, 0.4) 97.13%
  );
  .wapperTopTitle {
    font-family: PingFang SC;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #263b5f;
  }
  .wapperTopDesc {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #263b5f;
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .wapperSub {
    display: flex;
    align-content: center;
    justify-content: space-between;
    .wapperSubDesc {
      font-family: PingFang SC;
      font-size: 12px;
      line-height: 12px;
      display: inline-block;
      font-weight: 400;
      color: #263b5f80;
    }
    .wapperSmallBtn {
      display: flex;
      align-content: center;
      justify-content: center;
      position: relative;
      top: -2px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      .wapperBtnIcon {
        color: #263b5f80;
        font-size: 10px !important;
        line-height: 16px;
        vertical-align: middle;
      }
      .wapperBtnText {
        font-family: PingFang SC;
        vertical-align: middle;
        color: #263b5f80;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  .smallListCt {
    span {
      margin-top: 12px;
      color: #326ffd;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
.sendWapper {
  position: absolute;
  max-width: 1000px;
  width: calc(100%);
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
}
.sendWapperSmall {
  position: absolute;
  max-width: 1000px;
  width: calc(100% - 40px);
  bottom: 1px;
  left: 50%;
  transform: translateX(-50%);
}
.sendPart {
  padding: 10px 16px;
  position: relative;
  border-radius: 8px;
  border: 1px solid rgba(38, 59, 95, 0.1);

  background: #fff;
  display: flex;
  align-items: center;
  &:hover {
    border: 1px solid rgba(38, 59, 95, 0.5);
    .sendTextarea::placeholder {
      color: rgba(38, 59, 95, 0.3);
    }
  }
  .sendTextarea {
    --tw-shadow: 0 0 transparent;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border-color: transparent;
    border-radius: 0;
    border-width: 1px;
    font-size: 16px;
    width: calc(100% - 50px);
    line-height: 24px;
    height: auto;
    border: none;
    outline: none;
    resize: none;
    overflow: auto;
    background-color: transparent;
    color: #263b5f;

    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .sendTextarea::placeholder {
    color: rgba(38, 59, 95, 0.5);
  }

  .sendBtn {
    position: absolute;
    bottom: 6px;
    cursor: pointer;
    right: 16px;
    width: 36px;
    height: 36px;
    display: inline-block;
  }
  .hide {
    display: none;
  }
}
.chatWapperScroll {
  overflow-y: auto;
  position: relative;
  height: calc(100vh - 220px);
  &::-webkit-scrollbar {
    display: none;
  }
}

.shadow {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 99;
  display: inline-block;
  right: 0;
  height: 30px; /* 你可以自定义阴影高度 */
  background: linear-gradient(
    180deg,
    #fbfbfb 0%,
    #fafbfc 3.59%,
    rgba(249, 250, 252, 0) 100%
  );
}
.shadowBottom {
  position: absolute;
  bottom: 97px;
  left: 0;
  width: 100%;
  z-index: 99;
  display: inline-block;
  right: 0;
  height: 10px; /* 你可以自定义阴影高度 */
  background: linear-gradient(
    0deg,
    rgba(250, 251, 252, 0.5) 0%,
    #edf2fe 3.59%,
    rgba(249, 250, 252, 0) 100%
  );
}

.chatWapper {
  position: relative;
  padding-top: 40px;
  .chatTip {
    color: rgba(38, 59, 95, 0.5);
    text-align: center;
    font-family: PingFang SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .chatTipLink {
    color: #c058ff;
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    cursor: pointer;
    font-weight: 400;
    line-height: 24px;
  }
  .bottomTip {
    color: rgba(38, 59, 95, 0.5);
    text-align: center;
    font-family: PingFang SC;
    font-size: 12px;
    font-style: normal;
    margin-top: 16px;
    font-weight: 400;
    line-height: normal;
    .clearBtn {
      color: #326ffd;
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      cursor: pointer;
      font-weight: 400;
      line-height: 24px;
      &:hover {
        color: rgba(50, 111, 253, 0.7);
      }
    }
  }
}
.wapperLayer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 20px 0px;

  .question {
    padding: 12px 16px;
    max-width: calc(100% - 40px);
    display: inline-block;
    box-sizing: border-box;
    background: #326ffd;
    border-radius: 8px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* identical to box height, or 150% */
    align-self: flex-end;
    position: relative;

    color: #ffffff;

    &:hover {
      .wapperCopy {
        visibility: visible;
      }
    }

    .wapperCopy {
      position: absolute;
      visibility: hidden;
      top: 12px;
      right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .copyBtn {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 150% */
        margin-left: 6px;

        color: #ffffff;
      }
    }
  }

  .expandBtn {
    align-self: flex-start;
    margin-top: 30px;
    height: 24px;
    cursor: pointer;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    color: #fff;
  }
  .wapperBtn {
    align-self: flex-start;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    .btnActive {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 12px 24px;
      gap: 8px;
      line-height: 22px;
      cursor: pointer;
      height: 46px;
      box-sizing: border-box;
      background: linear-gradient(230.75deg, #6d0cfe 17.98%, #b235ff 84.06%);
      color: #ffffff;
      border-radius: 8px;
    }
    .btnNormal {
      cursor: pointer;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 12px 24px;
      gap: 8px;

      height: 46px;

      background: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      color: #ffffff;
    }
  }
}
.loadingCt {
  .loading {
    img {
      width: 32px;
      height: 32px;
      margin-top: 2px;
      display: inline-block;
    }
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 8px 0;
    text-align: left;
    color: #263b5f;
    line-height: 44px;
    height: 44px;
    border-radius: 8px;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    width: 180px;
    background: #e9ecf5;
    margin-bottom: 8px;
    .blink {
      margin-left: 4px;
      animation: blink 1s infinite steps(1);
    }
    @keyframes blink {
      50% {
        color: transparent;
      }
    }
  }
  .cancelBtn {
    color: rgb(50, 111, 253);
    cursor: pointer;
    font-family: PingFang SC;
    font-size: 14px;
    width: 82px;
    font-weight: 400;
    line-height: 20px;
    display: block;
    letter-spacing: 0em;
    text-align: center;
    &:hover {
      color: rgba(50, 111, 253, 0.7);
    }
  }
}
.wapperResult {
  display: inline-block;
  box-sizing: border-box;
  align-self: flex-start;
  min-width: 293px;
  max-width: 800px;
  padding: 12px 16px;
  border-radius: 8px;
  background: #e8ecf5;
  .resText {
    font-family: "PingFang SC";
    color: #263b5f;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .noResultTip {
    cursor: pointer;
    font-family: PingFang SC;
    font-style: normal;
    color: #a3a7b9;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
  }
  .explainText {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: PingFang SC;
    font-style: normal;
    color: #a3a7b9;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */
    .left {
      display: flex;
      align-items: center;
      margin-right: 40px;
      .link {
        color: #476cfe;
        cursor: pointer;
        padding-left: 4px;
        &:hover {
          color: rgba(50, 111, 253, 0.7);
        }
      }
    }

    .feedback {
      display: flex;
      gap: 2px;
      span {
        border-radius: 4px;
        display: flex;
        color: #263b5f99;
        font-size: 24px;
        padding: 4px;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        flex-shrink: 0;
        &:hover {
          color: #263b5f;
          background: rgba(174, 177, 203, 0.2);
        }
      }
    }
  }

  .preResult {
    position: relative;
    .feedbackPopover {
      position: absolute;
      right: 0px;
      bottom: -32px;
      display: none;
      padding: 4px;
      align-items: flex-start;
      gap: 4px;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
      span {
        font-size: 20px;
        border-radius: 4px;
        color: #263b5f;
        cursor: pointer;
        &:hover {
          color: #263b5f;
          background: rgba(174, 177, 203, 0.2);
        }
      }
    }
  }
}
.wapperResult:hover {
  .feedbackPopover {
    display: inline-flex;
  }
}
.hide {
  display: none;
}
.zanActive {
  color: #326ffd !important;
}
.cursorDisable {
  cursor: not-allowed !important;
}
.copyed {
  color: #326ffd !important;
}
.errorLinkPart {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  line-height: 22px;
  text-align: left;
  color: #263b5f;
  .retryLink {
    color: #326ffd !important;
    font-family: Alibaba PuHuiTi 2;
    font-size: 12px;
    font-weight: 400;
    width: 90px;
    text-align: left;
    cursor: pointer;
    margin-left: 8px;
  }
}

.notLink {
  padding-bottom: 8px;

  .title {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #263b5f;
  }
  .content {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin: 10px 0px 14px 0px;
    color: #263b5f;
  }
  .btn {
    width: 76px;
    height: 32px;
    padding: 6px 10px 6px 10px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    background: rgb(50, 111, 253);
    cursor: pointer;
    color: #fff;
    &:hover {
      opacity: 0.7;
    }
  }
}
.linkHover:hover {
  color: rgba(50, 111, 253, 0.7);
}
