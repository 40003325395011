.HelpLayer {
  display: inline-block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999;
  background: rgba(0, 0, 0, 0.5);
  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 286px;
    height: 277rem;

    background: rgba(0, 19, 53, 0.95);
    border: 1px solid rgba(2, 61, 165, 0.76);
    box-shadow: 0px 0px 8px #032667;
    .title {
      box-sizing: border-box;
      width: 100%;
      height: 40rem;
      padding: 10rem 20rem;
      display: inline-block;
      background: linear-gradient(
        90deg,
        rgba(0, 40, 111, 0.8) 0%,
        rgba(44, 121, 255, 0.33) 100%
      );

      position: absolute;

      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 500;
      font-size: 14rem;
      line-height: 20rem;

      color: #fff; /* 将文本颜色设为透明，以便背景颜色可见 */
    }

    .list {
      padding: 9rem 20rem 9rem 20rem;
      box-sizing: border-box;
      margin-top: 40rem;
      .listItem {
        height: 36rem;
        box-sizing: border-box;
        border-bottom: 1rem solid rgba(220, 220, 220, 0.1);
        padding: 8rem 0;
        .iconfont {
          color: white;
          font-size: 20rem;
          margin-right: 10rem;
          vertical-align: middle;
        }
        .text {
          font-family: "PingFang SC";
          vertical-align: middle;
          font-style: normal;
          font-weight: 400;
          font-size: 12rem;
          line-height: 17rem;
          /* identical to box height */

          color: #ffffff;
        }
      }
    }
  }
}
