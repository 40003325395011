.modal {
  .iconCloseWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.15s linear;

    &:hover {
      transform: rotate(180deg);
    }
    .iconClose {
      height: 12px;
      width: 12px;
      box-sizing: border-box;
      color: #ffffff;
    }
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    .icon {
      font-size: 24px;
      color: #f8a431;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  :global {
    .ant-modal-content {
      border-radius: 8px;
      .ant-modal-header {
        border-radius: 8px 8px 0 0;
        border-bottom: 1px solid #0000000f;
        .ant-modal-title {
          color: #2e2e2e;
        }
      }
      .ant-modal-body {
        color: #4f4f4f;
      }
      .ant-modal-close {
        right: -24px;
        top: -24px;
        .ant-modal-close-x {
          line-height: unset;
          width: 24px;
          height: 24px;
          background-image: url(../../image/close_border.svg);
        }
      }
      .ant-modal-footer {
        border-radius: 0 0 8px 8px;
        border-top: 1px solid #0000000f;
        .ant-btn {
          min-width: 65px;
          &:hover {
            color: #4585f5;
            border-color: #4585f5;
          }
        }
        .ant-btn-primary {
          border-color: #1b60e9;
          background: #1b60e9;
          &:hover {
            color: #fff;
            border-color: #4585f5;
            background: #4585f5;
          }
        }
      }
    }
  }
}
