.graphWapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: inline-block;
  .header {
    width: 100%;
    position: relative;
    .rightIcon {
      position: absolute;
      right: 100px;
      .icon {
        font-size: 28px;
        cursor: pointer;
        margin-left: 20px;
        display: inline-block;
        color: rgba(182, 181, 193, 1);
      }
    }
  }

  .part {
    width: 100vw;
    height: calc(100vh - 70px);
    display: flex;

    .leftPart {
      width: 400px;
      display: inline-block;
      position: relative;
      border-right: 1px solid #e0e2e5;
      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0) !important;
      }

      ::-webkit-scrollbar-thumb {
        background: #fff !important;
        -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0) !important;
      }
      &:hover {
        ::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2) !important;
        }
        ::-webkit-scrollbar-thumb {
          background: #a3a7b9 !important;
          -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2) !important;
        }
      }
    }

    .middlePart {
      background: url(../../image/chatGraph/graphBg.png) no-repeat center;
      background-size: cover;
      position: relative;
      flex: 1;

      :global {
        .ant-pagination-item {
          border-radius: 2px !important;
          height: 32px !important;
          border: 1px solid transparent !important;
          font-weight: 400;
          font-size: 14px !important;
          background: #f5f6f6 !important;
          &:hover {
            background: #ebf1ff !important;
          }
          /* identical to box height, or 140% */
        }
        .ant-pagination-item-active {
          border-radius: 2px !important;
          color: #fff !important;
          background: #326ffd !important;
        }
        .ant-pagination-item a {
          color: #394a70 !important;

          line-height: 32px !important;
        }
        .ant-pagination-item-active a {
          color: #fff !important;
        }
        .ant-pagination-item-ellipsis {
          color: #d8d8d8 !important;
        }
        .ant-pagination-prev,
        .ant-pagination-next {
          line-height: 32px !important;
          height: 32px !important;
        }
        .ant-pagination-prev .ant-pagination-item-link {
          background: #f5f6f6 !important;
          background-color: #f5f6f6 !important;
          color: #394a70 !important;
          border: 1px solid transparent !important;
          border-radius: 2px !important;
          line-height: 32px !important;
        }

        .ant-pagination-next .ant-pagination-item-link {
          background: #f5f6f6 !important;
          background-color: #f5f6f6 !important;
          color: #394a70 !important;
          border: 1px solid transparent !important;
          line-height: 32px !important;
          border-radius: 2px !important;
        }

        .ant-pagination-next .ant-pagination-item-link .anticon-right {
          color: #394a70 !important;
        }
        .ant-pagination-disabled .ant-pagination-item-link {
          color: #a1aacc !important;
        }
        .ant-pagination-disabled .ant-pagination-item-link .anticon-right {
          color: #a1aacc !important;
        }

        .ant-pagination-options .ant-select-selector {
          background: #061836 !important;
          border-radius: 2px !important;
          height: 32px !important;
          font-weight: 400;
          font-size: 14px !important;
          /* identical to box height, or 140% */

          color: #f5f5f5 !important;

          line-height: 32px !important;
        }
        .ant-pagination-total-text {
          color: #f5f5f5 !important;
          float: left;
        }
        .ant-pagination-options-quick-jumper {
          color: #f5f5f5 !important;
        }
        .ant-pagination-options-quick-jumper input {
          background: #061836 !important;
          color: #f5f5f5 !important;
          border-radius: 2px !important;
          border: 1px solid #3872ca !important;
        }
        .ant-pagination-options .ant-select-selector {
          background: transparent !important;
          background-color: transparent !important;
          color: #394a70 !important;
          border-radius: 2px !important;
          border: 1px solid #a3a7b9 !important;
        }
        .ant-select-selector:hover {
          border-radius: 2px;
          border: 1px solid #a3a7b9 !important;
        }
      }
    }
    .rightPart {
      width: 0px;
      display: none;
      background: #fbfcff;
      height: 100%;
      .chatContent {
        height: calc(100% - 80px);
      }
      .chatTop {
        width: 100%;
        height: 56px;
        padding: 16px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e0e2e5;
        .chatTitle {
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          color: #263b5f;

          letter-spacing: 0em;
          text-align: center;
        }
        .chatIcon {
          span {
            margin-left: 12px;
            vertical-align: middle;
            cursor: pointer;
            font-size: 24px;
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
    .rightPartShow {
      border-left: 1px solid #e0e2e5;
      display: inline-block;
      width: 400px;
    }
    .iconContainer {
      position: absolute;
      width: 56px;
      height: 56px;
      display: inline-block;
      cursor: pointer;
      bottom: 180px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      left: -28px;
      border-radius: 45px;
      background: #fff;
      box-shadow: 0px 4px 25px 0px rgba(38, 59, 95, 0.2);
      .focusBtn {
        color: #263b5fcc;
        font-size: 28px;
      }
      .iconText {
        color: #263b5fcc;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &:hover {
        .focusBtn {
          color: #263b5f;
        }
        .iconText {
          color: #263b5f;
        }
      }
    }
    .iconDetailContainer {
      position: absolute;
      width: 56px;
      height: 56px;
      display: inline-block;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      flex-flow: column;
      border-radius: 45px;
      background: #fff;
      box-shadow: 0px 4px 25px 0px rgba(38, 59, 95, 0.2);
      bottom: 100px;
      left: -28px;
      .viewDetailBtn {
        font-size: 28px;
        color: #263b5fcc;
      }
      .iconText {
        color: #263b5fcc;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &:hover {
        .viewDetailBtn {
          color: #263b5f;
        }
        .iconText {
          color: #263b5f;
        }
      }
    }

    .messagePng {
      position: absolute;
      width: 48px;
      height: 48px;
      cursor: pointer;
      display: inline-block;
      top: 16px;
      right: 28px;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.container {
  width: calc(100%);
  height: calc(100%);
}

.loading {
  width: 100% !important;
  height: 100vh !important;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: rgba(102, 102, 102, 0.6);
  text-align: center;
  padding-top: 40vh;
}

.slider {
  position: absolute;
  bottom: 20px;
  right: 10px;
  display: inline-block;
  height: 100px;
}

.footerBar {
  position: absolute;
  bottom: 10px;
  left: 10px;
  height: 60px;
  width: 100%;
  padding-left: 20px;
  justify-content: left;
  align-items: center;
  display: flex;
  .pagination {
    display: inline-block;
    :global {
      .ant-pagination-options-size-changer {
        background: #fff;
      }
    }
  }

  .totalBar {
    height: 20px;
    margin-right: 24px;
    display: inline-block;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */

    color: #b5b5b5;
  }
}
