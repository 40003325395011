@import "../theme.variable.less";

.container {
  background-color: #edf5ff;
  width: 100vw;
  height: auto;
}
.contentContainer {
  width: 1160px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  padding-bottom: 20px;
}

.leftSide {
  z-index: 1;
  width: 200px;
  //height: 820px;
  // height: calc(100vh - 129px - 20px - 20px);
  // max-height: 820px;
  position: fixed;
  top: 129px;
  background-color: @white;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 20px;
  .treeIcon {
    font-size: 16px !important;
    position: relative;
    top: -1px;
  }
  :global {
    .ant-collapse-content-box {
      padding: 4px 30px 4px 50px !important;
    }
    .ant-collapse-header {
      transition: all 0.2s linear !important;
      line-height: 20px !important;
      color: #263b5fb2 !important;

      &:hover {
        color: #326ffd;
        .ant-collapse-header-text {
          color: #326ffd;
        }
        .ant-collapse-expand-icon {
          color: #326ffd;

          .anticon {
            color: #326ffd;
          }
        }
      }
    }
    .ant-collapse-expand-icon {
      padding-inline-end: 7px !important;
      .anticon {
        color: #75777e;
        font-size: 18px;
      }
    }
    .ant-collapse-item-active {
      .ant-collapse-extra {
        .anticon {
          transform: rotateX(180deg);
        }
      }
    }
  }
}
.anchor {
  :global {
    .ant-anchor {
      &:before {
        display: none;
      }
    }
    .ant-anchor-ink-visible {
      display: none !important;
    }
    .ant-anchor-link {
      padding-block: 0 !important;
      padding-inline: 0 !important;
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }
    .ant-anchor-link-title-active {
      color: #326ffd;
    }
    .ant-anchor-link-title {
      color: @colorText;
    }
  }
  display: block;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 16px;
  transition: all 0.2s linear;
  color: #263b5f;
  &:hover {
    color: #326ffd;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.active {
    color: #326ffd;
    font-weight: bold;
  }
}

.rightSide {
  width: 920px;
  margin-left: -100px;
  margin-top: 98px;
}
