@keyframes myRotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.screenBg {
  background: url("./image/screenBg.png") no-repeat;
  background-size: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: initial !important;
  min-width: 1366px;
  min-height: 700px; /* Minimum Height */
}

/* 下拉框样式覆盖 */
.screenBg .ant-select-selector {
  border: 1px solid #1b55ab !important;
  border-radius: 2px !important;
  background-color: transparent !important;
  font-size: 14px !important;
  line-height: 20px !important;
  /* identical to box height */

  color: #ffffff !important;
}

.screenBg .ant-select-selector:hover {
  border: 1rem solid #3284ff !important;
}
.screenBg .ant-select-arrow {
  color: #ffffff !important;
}
.screenBg
  .ant-select-single.screenBg
  .ant-select-open
  .screenBg
  .ant-select-selection-item {
  color: #ffffff !important;
}

/* 下拉框样式 */
.screenBg .ant-select-item-option-active,
.screenBg .ant-select-item-option-selected {
  background: #3c69ab !important;
  background-color: #3c69ab !important;
  border-radius: 0px !important;
}

.screenBg .ant-select-item {
  background-color: #132f5a;
  background: #132f5a;
  border-radius: 4px;
  padding: 9px 12px !important;
  min-height: 40px !important;
}

.screenBg .ant-select-item:hover {
  background-color: #1d4178 !important;
  background: #1d4178 !important;
}

.screenBg .ant-select-dropdown {
  background: #132f5a;
  border-radius: 4px;
  padding: 0px !important;
}
.screenBg .ant-select-item-option-content {
  color: #ffffff;
}

.screenBg .ant-select-dropdown .screenBg .ant-select-item {
  border-radius: 0px !important;
}
.screenBg .ant-cascader-menu-item:hover {
  background: #0f3a77 !important;
  color: #fff;
}

.screenBg .ant-tree .ant-tree-switcher {
  line-height: normal !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.screenBg .ant-pagination-item {
  background: #061836 !important;
  border: 1px solid #3872ca !important;
  border-radius: 2px !important;
  height: 32px !important;
  font-weight: 400;
  font-size: 14px !important;
}
.screenBg .ant-pagination-item:hover {
  background: linear-gradient(
      0deg,
      rgba(32, 87, 171, 0.4),
      rgba(32, 87, 171, 0.4)
    ),
    linear-gradient(0deg, #3872ca, #3872ca) !important;
  border: 1px solid #3872ca !important;
}
.screenBg .ant-pagination-item a {
  color: #f5f5f5 !important;

  line-height: 32px !important;
}
.screenBg .ant-pagination .ant-pagination-item-active {
  background: #144999 !important;
  border: 1px solid #3872ca !important;
}
.screenBg .ant-pagination-item-ellipsis {
  color: #d8d8d8 !important;
}
.screenBg .ant-pagination-prev,
.screenBg .ant-pagination-next {
  line-height: 32px !important;
  height: 32px !important;
}
.screenBg .ant-pagination-prev .ant-pagination-item-link {
  background: #061836 !important;
  background-color: #061836 !important;
  color: #d8d8d8 !important;
  border: 1px solid #3872ca !important;
  border-radius: 2px !important;
  line-height: 32px !important;
}

.screenBg .ant-pagination-next .ant-pagination-item-link {
  background: #061836 !important;
  background-color: #061836 !important;
  color: #d8d8d8 !important;
  border: 1px solid #3872ca !important;
  line-height: 32px !important;
  border-radius: 2px !important;
}
.screenBg .ant-pagination-prev .ant-pagination-item-link:hover {
  background: linear-gradient(
      0deg,
      rgba(32, 87, 171, 0.4),
      rgba(32, 87, 171, 0.4)
    ),
    linear-gradient(0deg, #3872ca, #3872ca) !important;
  border: 1px solid #3872ca !important;
}
.screenBg .ant-pagination-next .ant-pagination-item-link:hover {
  background: linear-gradient(
      0deg,
      rgba(32, 87, 171, 0.4),
      rgba(32, 87, 171, 0.4)
    ),
    linear-gradient(0deg, #3872ca, #3872ca) !important;
  border: 1px solid #3872ca !important;
}

.screenBg .ant-pagination-prev .ant-pagination-item-link .anticon-right {
  color: #d8d8d8 !important;
}
.screenBg .ant-pagination-next .ant-pagination-item-link .anticon-right {
  color: #d8d8d8 !important;
}
.screenBg .ant-pagination-disabled .ant-pagination-item-link {
  color: #d8d8d8 !important;
}

.screenBg .ant-pagination-disabled .ant-pagination-item-link {
  color: #a1aacc !important;
}
.screenBg .ant-pagination-disabled .ant-pagination-item-link .anticon-right {
  color: #a1aacc !important;
}
.screenBg .ant-pagination-options .ant-select-selector {
  background: #061836 !important;
  border: 1px solid #3872ca !important;
  border-radius: 2px !important;
  height: 32px !important;
  font-weight: 400;
  font-size: 14px !important;
  color: #f5f5f5 !important;
  line-height: 32px !important;
}
.screenBg .ant-pagination-options .ant-select-selector:hover {
  border: 1px solid #3284ff !important;
}
.screenBg .ant-pagination-total-text {
  color: #f5f5f5 !important;
  float: left;
}
.screenBg .ant-pagination-options-quick-jumper {
  color: #f5f5f5 !important;
}
.screenBg .ant-pagination-options-quick-jumper input {
  background: #061836 !important;
  color: #f5f5f5 !important;
  border-radius: 2px !important;
  border: 1px solid #3872ca !important;
}
.screenBg .ant-pagination-options-quick-jumper input:hover {
  border: 1px solid #3284ff !important;
}
.screen-wrapper {
  display: inline-block;
  min-width: 1366rem;
  min-height: 768rem; /* Minimum Height */

  /* width: 100%;
  height: 100%; */
  /* background: url("./image/screenBg.png") no-repeat;
  background-size: cover;
  object-fit: cover; */
  /* background: url("./image/screenBg.png") no-repeat; */
}
.screen {
  display: inline-block;
  width: 100%;
  height: 100%;
  /* transform-origin: 0 0; */
  /* left: 50%; */
}

.scroll-ct {
  display: inline-block;
  width: 20rem;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 50;
  background-color: transparent;
}

.shadow-ct {
  width: 100%;
  display: inline-block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  /* inner gradient shadow */
  z-index: 40;
  pointer-events: none;
  /* background: url("./image/screenBg-front.png") no-repeat; */
  background-size: cover;
}

.loading-ct {
  display: inline-block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999;
  background: rgba(0, 0, 0, 0.5);
}
.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: myRotate 1.6s linear infinite;
}

.title-part {
  width: 1669rem;
  height: 92rem;
  position: absolute;
  left: 125rem;
  background: url("src/image/gkx/title.svg") no-repeat;
  z-index: 50;
  background-size: cover;
}

.logo-part {
  width: 245rem;
  position: absolute;
  left: 50rem;
  z-index: 50;
  cursor: pointer;
  top: 60rem;
  height: 48rem;
  margin: 0 auto;
  background: url("src/image/gkx/gkxlogo.svg") no-repeat;
  background-size: contain;
}
.nav-part {
  width: 45rem;
  position: absolute;
  left: 50rem;
  z-index: 50;
  cursor: pointer;
  top: 120rem;
  color: #fff;
  height: 48rem;
  margin: 0 auto;
}

.g6-component-ct-org {
  padding: 0 6rem;
  display: inline-block;
  /* box-shadow: 0px 0px 8px rgba(51, 146, 255, 0.5); */
  /* padding: 20px 18px; */
}

.g6-component-ct-org-img {
  object-fit: cover;
  width: 90rem;
  height: 60rem;
  vertical-align: middle;
  display: inline-block;
  margin: 0rem;
  margin-right: 12rem;
  box-sizing: border-box;
}
.g6-component-ct-org-detail {
  display: inline-block;
  vertical-align: middle;
  margin-top: -10rem;
}
.g6-component-ct-org-title {
  font-weight: 400;
  font-size: 14rem;
  line-height: 33rem;
  cursor: pointer;
  /* identical to box height */

  color: #000000;
  display: block;
}
.g6-component-ct-person {
  padding: 0 6rem;
  display: inline-block;
}

.g6-component-ct-person-img {
  width: 60rem;
  object-fit: cover;
  height: 60rem;
  border-radius: 60rem;
  vertical-align: middle;
  display: inline-block;
  margin: 0rem;
  margin-right: 12rem;
  box-sizing: border-box;
}

.g6-component-ct-person-detail {
  display: inline-block;
  vertical-align: middle;
}

.g6-component-ct-person-title {
  font-weight: 500;
  margin-bottom: 4rem;
  font-size: 14rem;
  line-height: 20rem;
  cursor: pointer;
  /* identical to box height */

  color: #000000;
}

.g6-component-ct-count {
  font-weight: 400;
  font-size: 12rem;
  line-height: 17rem;
  /* identical to box height */

  color: #363942;
}

.g6-component-tooltip {
  background: #e2e9ff;
  box-shadow: 0rem 0rem 8rem rgba(51, 146, 255, 0.5);
  border-radius: 4rem;
  width: fit-content;
  padding: 6rem 16rem;
  color: #000;
  font-weight: 400;
  font-size: 12rem;
  line-height: 17rem;
}

.g6-component-img {
  display: inline-block;
  width: 60rem;
  height: 60rem;
}

.g6-component-close {
  cursor: pointer;
}

.g6-toolbar-ul {
  position: fixed;
  bottom: -2rem;
  left: 125rem;
  text-align: center;
  width: 1673rem;
  padding: 0px;
  height: 56rem;
  background: url("./image/gkx/toolbar.svg") no-repeat;
  background-size: cover;
}

.g6-toolbar-ul:hover {
  text-align: center;
  width: 1673rem;
  padding: 0px;
  height: 56rem;
  background: url("./image/gkx/toolbar-active.svg") no-repeat;
  background-size: cover;
}

.iconfont-part {
  display: flex;
  width: 203rem;
  margin-left: 738rem;
  justify-content: space-between;
  align-items: flex-start;
}

.g6-toolbar-ul li {
  display: inline-block;
  cursor: pointer;
  width: 38rem;
  height: 20rem;
  margin-top: 18rem;
  gap: 20rem;
}
.g6-toolbar-ul .iconfont {
  color: #3392ff;
  font-size: 38rem;
  line-height: 30rem;
  text-align: center;
  position: relative;
  top: 2rem;
}

.g6-toolbar-ul .iconfont:hover {
  color: #fff;
}

.g6-tooltip {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  font-size: 12px;
  color: #545454;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px 8px;
  box-shadow: rgb(174, 174, 174) 0px 0px 10px;
}
