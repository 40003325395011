.CenterGraph {
  box-sizing: border-box;
  position: absolute;
  width: 99%;
  height: calc(100% - 160px);
  margin-top: 120px;
  left: 0rem;
  top: 0rem;
  .topShadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    height: 130px;
    background: linear-gradient(
      180deg,
      #041b47 90.95%,
      rgba(8, 26, 62, 0) 122.94%
    );
  }
  // background: url("../../image/centerCommonBg.svg") no-repeat;
  // background-size: contain;
}

@media screen and (min-width: 1920px) {
  .CenterGraph {
    height: calc(100% - 160px);
    margin-top: 102px;
    .topShadow {
      height: 160px;
    }
  }
}
.graph {
  z-index: 20;
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.help {
  position: absolute;
  width: 134rem;
  height: 44rem;
  left: 63%;
  bottom: -10rem;
  padding: 4rem 12rem;
  background: #e2e9ff;
  box-shadow: 0rem 0rem 8rem rgba(169, 193, 245, 0.5);
  border-radius: 4rem;
}
