.chat {
  width: 100%;
  // background: #06104b;
  position: relative;
  height: 100vh;
  padding-top: 80px;
  overflow: hidden;
  .chatContent {
    width: 100%;
    // height: calc(100% - 120px);
    text-align: center;
    .chatTitle {
      color: #fff;
      font-family: PingFang SC;
      font-size: 56px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 15vh;
    }
    .chatSubTitle {
      color: rgba(255, 255, 255, 0.6);
      font-family: PingFang SC;
      font-size: 28px;

      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .searchPart {
      position: relative;
      left: -60px;
      margin-top: 20px;
      height: 122px;
      .tags {
        max-width: 784px;
        margin: 0 auto;
        margin-bottom: -26px;
        height: 60px;
        overflow: hidden;
        text-align: left;
        .tag {
          color: #fff;
          text-align: center;
          width: 172px;
          font-family: PingFang SC;
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          opacity: 0.7;
          height: 36px;
          cursor: pointer;
          display: inline-block;
          line-height: 36px;
          // transition: all ease 0.3s;
          position: relative;
          top: 18px;
          margin-right: 6px;
          margin-left: -52px;
          z-index: 1;
          background: url(./image/chatGraph/tabNormal.svg) no-repeat;
          &:first-child {
            margin-left: -34px;
          }
          &:nth-child(2) {
            z-index: 2;
          }
          &:hover {
            opacity: 0.9;
            // background: url(./image/chatGraph/tabNormalHover.png) no-repeat;
          }
        }
        .tagActive {
          height: 40px;
          opacity: 1 !important;
          font-size: 20px;
          font-weight: 600;
          line-height: 40px;
          z-index: 99;
          color: #326ffd;
          top: 15px;
          background: url(./image/chatGraph/tabRaceActive.svg) no-repeat;
          &:hover {
            background: url(./image/chatGraph/tabRaceActive.svg) no-repeat;
          }
        }
      }

      .searchBox {
        position: relative;
        margin: 0 auto;
        z-index: 10;
        max-width: 784px;
        height: 64px;
        .searchCmp {
          width: 100%;
          height: 64px;
          border-radius: 0px 0px 0px 12px;
          margin-top: 20px;
          background: #fff;
          padding: 8px;
          .searchInput {
            display: inline-block;
            vertical-align: middle;
            width: calc(100% - 96px);
            height: 100%;
            border: none;
            color: #a3a7b9;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            box-shadow: none;
            font-weight: 400;
            line-height: normal;
            line-height: 40px;
            border: none; // 移除边框
            outline: none; // 移除焦点时的轮廓

            :global {
              .ant-input-suffix {
                display: inline-block;
                width: 20px;
                height: 20px;
                position: absolute;
                // right: 4px;
                top: 10px;
                &:hover {
                  opacity: 0.6;
                }
              }
              .ant-input-affix-wrapper {
                &:focus {
                  border: none;
                  box-shadow: none;
                  outline: none;
                }
                &:hover {
                  border: none;
                  outline: none;
                  box-shadow: none;
                }
              }
            }
            // 处理某些浏览器在输入框被聚焦时自动应用的一些样式
            &:focus {
              color: rgba(38, 59, 95, 1);
              border: none;
              outline: none;
            }

            // 对于某些浏览器可能在输入框不可用时添加的边框样式也进行处理
            &:disabled {
              border: none;
              outline: none;
            }

            // 在输入框为无效状态时（如表单验证失败）也确保没有边框
            &:invalid {
              border: none;
              outline: none;
            }
          }

          .searchBtn {
            width: 50px;
            display: inline-block;
            height: 48px;
            margin-left: 23px;
            text-align: center;
            color: #fff;
            font-family: PingFang SC;
            font-size: 24px;
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #fff;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 500;
            line-height: 48px;
            vertical-align: middle;
            color: #326ffd;

            &:hover {
              opacity: 0.7;
            }
          }
          .split {
            width: 1px;
            font-size: 24px;
            position: relative;
            top: 8px;
            color: #e0e2e5;
          }
        }
        .jumpChatBox {
          width: 108px;
          height: 64px;
          display: flex;
          background: #fff;
          align-items: center;
          justify-content: left;
          border-radius: 0px 8px 8px 0px;
          top: 0px;
          position: absolute;
          right: -89px;
          .jumpChat {
            width: 99px;
            height: 44px;
            flex-shrink: 0;
            background: #fff;
            background: #fff;
            display: flex;
            justify-content: center;
            cursor: pointer;
            border-radius: 4px;

            align-items: center;
            color: #326ffd;
            font-family: PingFang SC;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            .duihua {
              font-size: 24px;
              margin-right: 8px;
            }

            &:hover {
              background: #326ffd;
              color: #fff;
              .duihua {
                color: #fff;
              }
            }
          }
        }
      }
      .promptBox {
        margin: 0 auto;
        text-align: left;
        max-width: 784px;
        margin-top: 12px;
        .promptLabel {
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(255, 255, 255, 0.6);
        }
        .promptText {
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 400;
          cursor: pointer;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          span {
            color: rgba(255, 255, 255, 1);

            &:hover {
              color: #326ffd;
            }
          }
        }
      }
    }

    .infoPart {
      width: 872px;
      margin-top: 70px;
      margin-left: -30px;
      height: 38vh;
      padding: 16px 36px;
      padding-right: 10px;
      border-radius: 12px;
      display: inline-block;
      // background: rgba(255, 255, 255, 0.1);
      fill: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.08) 0%,
        rgba(252, 253, 255, 0.08) 100%
      );
      box-shadow: 0px 4px 6px 0px rgba(255, 248, 248, 0.1) inset,
        4px 0px 6px 0px rgba(255, 255, 255, 0.1) inset,
        -4px 0px 4px 0px rgba(255, 255, 255, 0.1) inset,
        0px -4px 4px 0px rgba(255, 255, 255, 0.1) inset;
      filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.3));
      backdrop-filter: blur(40px);
      // border: 3px solid rgba(255, 248, 248, 0.1);
      .infoTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .infoJumpBtn {
        color: #5487ff;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 26px;
        cursor: pointer;
        &:hover {
          color: #326ffd;
        }
      }
    }
    .downGif {
      width: 32px;
      height: 32px;
      position: fixed;
      cursor: pointer;
      bottom: 4px;
    }

    // .stats {
    //   margin: 0 auto;
    //   position: fixed;
    //   bottom: 0;
    //   left: 0;
    //   background: rgba(38, 59, 95, 0.4);
    //   margin-top: 25px;
    //   height: 112px;
    //   width: 100vw;
    //   display: flex;
    //   gap: 20px;
    //   align-items: center;
    //   gap: 100px;
    //   justify-content: center;
    //   .stat {
    //     color: rgba(255, 255, 255, 0.8);
    //     font-family: PingFang SC;
    //     font-size: 16px;
    //     font-style: normal;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     margin-right: 4px;
    //     padding: 4px;
    //     font-weight: 400;
    //     cursor: pointer;
    //     &:hover {
    //       .statsIcon {
    //         opacity: 1 !important;
    //       }
    //     }
    //     .statLeft {
    //       width: 48px;
    //       display: flex;
    //       align-items: center;
    //       border-radius: 24px;
    //       justify-content: center;
    //       background-color: #283f84;
    //       height: 48px;
    //       .statsIcon {
    //         color: rgba(255, 255, 255, 0.8);
    //         font-size: 32px;
    //         vertical-align: text-bottom;
    //         font-style: normal;
    //         font-weight: 400;
    //         opacity: 0.6;
    //       }
    //     }
    //     .statRight {
    //       padding-left: 12px;
    //       display: flex;
    //       flex-flow: column;
    //       align-items: self-start;
    //       justify-content: left;

    //       .label {
    //         font-family: PingFang SC;
    //         font-size: 12px;
    //         font-weight: 400;
    //         color: #fff;
    //         line-height: 17px;
    //         letter-spacing: 0em;
    //         text-align: left;
    //       }
    //       .count {
    //         font-family: PingFang SC;
    //         color: #fff;
    //         font-size: 20px;
    //         font-weight: 500;
    //         line-height: 28px;
    //         letter-spacing: 0em;
    //         text-align: left;
    //         min-width: 100px;
    //       }
    //     }
    //   }
    //   .quickJump {
    //     color: rgba(255, 255, 255, 0.6);
    //     position: absolute;
    //     font-size: 16px;
    //     cursor: pointer;
    //     right: 40px;
    //     &:hover {
    //       color: #fff;
    //     }
    //   }
    // }
  }

  // @media screen and (max-width: 1520px) {
  //   .searchPart,
  //   .chatSubTitle,
  //   .chatTitle,
  //   .infoPart,
  //   .stat {
  //     transform: scale(0.8);
  //   }
  //   .stats {
  //     gap: 40px !important;

  //     height: 80px !important;
  //   }
  //   .quickJump {
  //     font-size: 14px !important;
  //   }
  //   // .stats {
  //   //   .stat {
  //   //     font-size: 14px !important;
  //   //   }
  //   //   .statsIcon {
  //   //     font-size: 16px !important;
  //   //   }
  //   // }
  // }

  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
    object-fit: cover;
    z-index: -1;
  }
}
