.IndustryPart {
  box-sizing: border-box;
  width: 100%;
  min-height: 280rem;
  position: relative;
  height: 46%;
  background: linear-gradient(
    271.01deg,
    rgba(0, 17, 48, 0.8) 82.36%,
    rgba(0, 17, 48, 0.56) 109.1%
  );
  .title {
    position: absolute;
    width: 92rem;
    height: 28rem;
    left: 36rem;
    top: 20rem;
  }

  .list {
    margin: 0 auto;
    margin-top: 56rem;
    margin-left: 20rem;
    z-index: 20;
    position: absolute;
    display: inline-block;
    height: calc(100% - 80rem);
    overflow-y: hidden;
    &:hover {
      overflow-y: auto;
    }
    .listItem {
      display: inline-block;
      padding: 16rem 10rem;
      vertical-align: top;
      &:nth-child(odd) {
        background: rgba(53, 87, 143, 0.1);
      }
      &:hover {
        background: #052f67 !important;
      }
      // cursor: pointer;
      .listImg {
        display: inline-block;
        height: 48rem;
        vertical-align: middle;
        width: 76rem;
        margin-right: 14rem;
        // border: 1rem solid #fff;
      }
      .rightPart {
        display: inline-block;
        width: 200rem;
        height: 48rem;
        vertical-align: middle;
        min-width: 200px;

        .listName {
          width: 188rem;
          display: inline-block;
          font-style: normal;
          font-weight: 400;
          font-size: 14rem;
          line-height: 20rem;
          cursor: pointer;
          color: #ffffff;
          &:hover {
            color: #5d8dd3;
          }
        }
        .countDesc {
          font-weight: 400;
          font-size: 12rem;
          line-height: 17rem;
          /* identical to box height */

          color: #c1c1c1;
        }
      }
    }
  }
}

.closeLayer {
  width: 48rem;
  height: 306rem;
  z-index: 50;
  right: 0rem;
  top: 428rem;

  position: absolute;
  background: url("../../image/layerClose.svg") no-repeat;

  .openBtn {
    position: absolute;
    left: 6rem;
    top: 134rem;
    display: inline-block;
    cursor: pointer;
    width: 20rem;
    height: 20rem;
    background: url("../../image/common/close.svg") no-repeat;
  }
  .openTitle {
    position: absolute;
    width: 14rem;
    height: 212rem;
    right: 10rem;
    top: 102rem;

    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 600;
    font-size: 14rem;
    line-height: 20rem;

    color: #3392ff;

    text-shadow: 0rem 0rem 9rem #0e4df3;
  }
}

.hide {
  display: none;
  transition: all 0.6s linear;
}
