@import "./iconfont/iconfont.css";
@import "./tailwind.css";

* {
  box-sizing: border-box;
}
html {
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-x: hidden;
}
#root {
  width: 100%;
  height: 100%;
}
p {
  margin: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* 滚动槽 */
::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 3px;
  background: transparent;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #a3a7b9;
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

.text-three-ellipsis {
  overflow: hidden;

  text-overflow: ellipsis;

  display: -webkit-box;

  -webkit-line-clamp: 3;

  /*! autoprefixer: off */

  -webkit-box-orient: vertical;
}

.text-two-ellipsis {
  overflow: hidden;

  text-overflow: ellipsis;

  display: -webkit-box;

  -webkit-line-clamp: 2;

  /*! autoprefixer: off */

  -webkit-box-orient: vertical;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

table {
  border-collapse: collapse; /* 让表格的边框合并为一个 */
  margin: 12px 0;
}

td,
th {
  border: 1px solid #ebeef7; /* 为所有单元格设置边框 */
  width: min-content; /* 让单元格宽度根据内容自动调整 */
  overflow: auto; /* 如果内容超过最大宽度，就显示滚动条 */
  text-align: center; /* 让文本在水平方向上居中 */
  vertical-align: middle; /* 让文本在垂直方向上居中 */
}
th {
  border: 1px solid #ebeef7;
  padding: 4px 16px;
  color: #a3a7b9;
  background: #f8f9ff;
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
td {
  padding: 8px 16px;
  background: #fff;
  color: #263b5f;
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
a {
  color: #326ffd;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}
.slideIn-enter {
  transform: translateY(100%);
}
.slideIn-enter-active {
  transform: translateY(0);
  transition: all 638ms ease-in-out;
}
.slideOut-enter {
  transform: translateY(0);
}
.slideOut-enter-active {
  transform: translateY(100%);
  transition: all 638ms ease-in-out;
}

.ant-cascader-dropdown {
  top: 128rem !important;
}

@media screen and (max-width: 1520px) {
  .ant-cascader-dropdown {
    top: 158rem !important;
  }
}

.ant-cascader-dropdown .ant-cascader-menu-item {
  border-radius: 0px !important;
  height: 40px;
  position: relative;
  left: 1px;
}
.ant-cascader-dropdown .ant-cascader-menu-item .iconfont {
  color: #fff;
}
.ant-cascader-dropdown .ant-cascader-menu {
  padding: 0px;
}
.ant-cascader-menu-item-content {
  /* width: 100px; */
  margin-right: 40px;
  font-size: 14px;
}
.ant-cascader-menus {
  color: #a1aacc;
  position: relative;
  padding-top: 10px;
}

/* .ant-select-dropdown {
  background: transparent !important;
} */
.ant-cascader-menus::before {
  content: "";
  width: 0;
  height: 0;
  display: inline-block;
  border: 8px solid transparent;
  border-bottom: 8px solid #588ef8;
  position: absolute;
  top: -6px;
  left: 40px;
}

.ant-cascader-menus::after {
  content: "";
  width: 0;
  height: 0;
  display: inline-block;
  border: 10px solid transparent;
  border-bottom: 10px solid #0b1c4d;
  position: absolute;
  top: -6px;
  left: 38px;
}

.ant-cascader-menu:nth-child(3) .ant-cascader-menu-item-content {
  margin-right: 16px;
}

.ant-select-dropdown {
  border-radius: 0px !important;
}
.ant-cascader-menu {
  background: linear-gradient(
    180deg,
    rgba(3, 30, 77, 0.95) 0%,
    rgba(5, 35, 89, 0.95) 100%
  );
  height: 300px !important;
  border: 1px solid #588ef8;
  width: 200px !important;
  padding-top: 8px !important;
  box-sizing: border-box !important;
  overflow-x: hidden !important;
}
.ant-cascader-menu-item-active {
  background: #0f3a77 !important;
}
.ant-cascader-menu-item-active .icon-youjian {
  color: #fff !important;
}
.anticon-right {
  color: #a1aacc;
}
.ant-cascader-menu-item-active .anticon-right {
  color: #0d2850 !important;
}

.ant-cascader-menu-item-active .ant-cascader-menu-item-content {
  color: #fff !important;
}
.linksPopover .ant-popover-inner {
  padding: 8px 4px;
}
.linkUrl {
  color: #326ffd;
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  border-radius: 3px;
  padding: 4px 8px 4px 8px;
  align-items: center;
  line-height: normal;
  cursor: pointer;
  max-width: 2000px;
}

.linkUrl:hover {
  background: #f8f9ff;
}

.headerLogin .ant-popover-inner {
  padding: 0px;
}
:where(.css-dev-only-do-not-override-1m62vyb).ant-input-affix-wrapper
  .ant-input-prefix
  > *:not(:last-child),
:where(.css-dev-only-do-not-override-1m62vyb).ant-input-affix-wrapper
  .ant-input-suffix
  > *:not(:last-child) {
  margin-inline-end: 10px;
}
:where(.css-dev-only-do-not-override-1m62vyb).ant-slider .ant-slider-track {
  background-color: #426ef4 !important;
}
:where(.css-dev-only-do-not-override-1m62vyb).ant-slider
  .ant-slider-handle::after {
  box-shadow: 0 0 0 2px #426ef4 !important;
}
.ant-popover {
  z-index: 999999999999;
}
