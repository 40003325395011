.TopBar {
  position: fixed;
  height: 36rem;
  left: 50%;
  transform: translateX(-50%);
  top: 80px;
  z-index: 100;

  .item {
    width: 124rem;
    height: 42rem;
    display: inline-block;
    left: 0rem;
    top: 0rem;
    cursor: pointer;
    text-align: center;
    font-weight: 500;
    font-size: 16rem;
    line-height: 32rem;
    position: relative;
    color: #b6cadc;

    &:hover {
      color: #3284ff;
    }

    &:last-child::before {
      content: "";
      display: inline-block;
      height: 12rem;
      width: 2rem;
      background: transparent;
      position: absolute;
      right: 0rem;
      top: 12rem;
    }
    &::before {
      content: "";
      display: inline-block;
      height: 12rem;
      width: 2rem;
      background: #164d9c;
      position: absolute;
      right: 0rem;
      top: 12rem;
    }

    .overviewName {
      width: 84rem;
      height: 37rem;
      vertical-align: middle;
      display: inline-block;
      .iconfontDown {
        font-size: 10rem;
        margin-left: 6rem;
        vertical-align: top;
      }
      &:hover {
        .iconfontDown {
          color: #3284ff;
        }
      }

      .iconfont {
        margin-left: 4rem;
        color: white;
        font-size: 12rem;
      }
    }
  }

  .active .overviewName {
    background: url("../../image/gkx/tab.svg") no-repeat;
    background-size: contain;
    color: #fff !important;
    background-position: center 0rem;

    .iconfont {
      color: #fff !important;
    }
  }
}

@media screen and (min-width: 1920px) {
  .TopBar {
    top: 100px;
  }
}
@media screen and (max-height: 800px) {
  .TopBar {
    // top: 10px;
  }
}
